import { Grid, Typography, useMediaQuery } from "@mui/material";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import HauslaneLogo from "../assets/HauslaneLogoFinal.PNG";
import demo from "../assets/salguerro.gif";
import easyDiscovery from "../assets/EasyDiscovery.PNG";

export default function Main({ handleOpenWaitlist }) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();
  console.log("isMobile", isMobile);
  return (
    <>
      <div style={{ height: "max-content", textAlign: "center" }}>
        <Grid
          container
          sx={{
            margin: "0 auto",
            paddingTop: isMobile ? 2 : 0,
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ verticalAlign: "middle", display: "inline-block" }}
          >
            <Typography
              variant="h1"
              style={{
                fontSize: isMobile ? 25 : 55,
                color: "black",
                fontWeight: 600,
                marginBottom: 20,
              }}
            >
              GIGL
            </Typography>
            <Typography
              style={{
                fontSize: isMobile ? 16 : 22,
                color: "black",
                textAlign: "center",
                marginBottom: 5,
              }}
            >
              Unlimited Audience, One Solution
            </Typography>
            <Typography
              variant="h1"
              style={{
                fontSize: isMobile ? 25 : 42,
                color: "black",
                fontWeight: 600,
              }}
            >
              Influencing Made Easy
            </Typography>
            {/* <Typography variant="body1">The world's first platform that connects brands and creators together with one swipe.</Typography> */}
            <div style={{display:"flex", gap: 10}}>
            <Button
              style={{
                display: "block",
                margin: "0 auto",
                backgroundColor: "#4158D0",
                backgroundImage:
                  "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                fontSize: isMobile ? 16 : 22,
                fontWeight: 600,
                marginTop: isMobile ? 20 : 40,
                width: isMobile ? "" : 300,
              }}
              onClick={() => navigate("/register")}
            >
              For Business
            </Button>
            <Button
              style={{
                display: "block",
                margin: "0 auto",
                backgroundColor: "#4158D0",
                backgroundImage:
                  "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                fontSize: isMobile ? 16 : 22,
                fontWeight: 600,
                marginTop: isMobile ? 20 : 40,
                width: isMobile ? "" : 300,
              }}
              onClick={() => navigate("/creatorsignup")}
            >
              For Creators
            </Button>
            </div>
          </Grid>
          <Grid item xs={11} md={5}>
            <img
              fetchpriority
              autoplay
              loop
              muted
              playsinline
              src={demo}
              style={{
                ...(isMobile && { marginTop: 1 }),
                height: isMobile ? 500 : 650,
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            margin: "0 auto",
            paddingTop: isMobile ? 1 : 0,
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "rgb(0 0 0)", minHeight: 20 }}
        >
          {/* <Typography
            style={{
              fontSize: isMobile ? 11 : 20,
              color: "white",
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            Here's What Top Brands Have To Say About Gigl
          </Typography> */}
        </Grid>

        {isMobile ? (
                    
    <Grid           
    sx={{ textAlign: "center", marginTop: 2 }}
              alignItems="center"
              justifyContent="center">
    <Grid  sx={{ marginTop: 10}}>
    <div
      alignItems="left"
      display="flex"
      style={{
        marginTop: 20,
        overflowX: "hidden",
        overflowY: "hidden",
        marginTop: 20,
        
      }}
    >
      <div
        style={{
          float: isMobile ? "" : "left",
          maxWidth: isMobile ? "100vw" : "50vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "center" : "left",
        }}
      >
    <Typography
      style={{
        fontSize: 16,
      }}
    >
      Easy Discovery on Gigl
    </Typography>
    <div
      style={{ marginTop: -10, textAlign: isMobile ? "center" : "left" }}
    >
      <Typography
        style={{
          fontSize: isMobile ? 18 : 35,
          fontWeight: 600,
          marginTop: 20,
        }}
      >
        Indentify Top Creators
      </Typography>
      <Typography
        style={{ fontSize: isMobile ? 18 : 35, fontWeight: 600 }}
      >
        With Your Easy Discovery Toolset
      </Typography>
    </div>
    <p
      style={{
        width: isMobile ? "80vw" : "35vw",
        ...(isMobile && { margin: "0 auto" }),
        fontSize: isMobile ? 10 : 16,
        marginTop: isMobile ? 10 : 15,
        width: "70%",
      }}
    >
      Find the right creators for your next campaign using Gigl's Creator Discovery Tools with advanced points and filters.

    </p>
  </div>
</div>
</Grid>
<Grid >
    <img
            alignItems="center"
            fetchpriority="high"
            src={easyDiscovery}
            height="100%"
            style={{
              float: "center",
              height: 380,
              width: "97%",
              marginTop: 0, 
            }}
          ></img>
      </Grid>
</Grid>

          ) : (
            <>
        
          
<Grid container sx={{marginTop: 2}}>
<Grid item xs={5} sx={{ marginTop: 10}}>
<div
  alignItems="left"
  display="flex"
  style={{
    marginTop: 20,
    overflowX: "hidden",
    overflowY: "hidden",
    marginTop: 20,
    
  }}
>
  <div
    style={{
      float: isMobile ? "" : "left",
      maxWidth: isMobile ? "100vw" : "50vw",
      marginLeft: isMobile ? 0 : 100,
      textAlign: isMobile ? "center" : "left",
    }}
  >
    <Typography
      style={{
        fontSize: isMobile ? 15 : 24,
        marginTop: isMobile ? 0 : 40,
      }}
    >
      Easy Discovery on Gigl
    </Typography>
    <div
      style={{ marginTop: -10, textAlign: isMobile ? "center" : "left" }}
    >
      <Typography
        style={{
          fontSize: isMobile ? 16 : 35,
          fontWeight: 600,
          marginTop: 20,
        }}
      >
        Indentify Top Creators
      </Typography>
      <Typography
        style={{ fontSize: isMobile ? 16 : 35, fontWeight: 600 }}
      >
        With Your Easy Discovery
      </Typography>
      <Typography
        style={{ fontSize: isMobile ? 16 : 35, fontWeight: 600 }}
      >
        Toolset
      </Typography>
    </div>
    <p
      style={{
        width: isMobile ? "80vw" : "35vw",
        ...(isMobile && { margin: "0 auto" }),
        fontSize: isMobile ? 10 : 16,
        marginTop: isMobile ? 10 : 15,
        width: isMobile ? "80%" : "90%",
      }}
    >
      Find the right creators for your next campaign using Gigl's Creator Discovery Tools with advanced points and filters.

    </p>
    <Button
      style={{
        marginTop: 20,
        marginBottom: isMobile ? 20 : 5,
        fontSize: isMobile ? 15 : 20,
      }}
      href="/register"
      target="_blank"
    >
      Get Started Today
    </Button>
  </div>
</div>
</Grid>
<Grid item xs={7}>
    <img
            alignItems="right"
            fetchpriority="high"
            src={easyDiscovery}
            height="100%"
            style={{
              float: isMobile ? "" : "right",
              borderRadius: 20,
              maxHeight: isMobile ? 400 : 700,
              maxWidth: isMobile ? "40vw" : "110%",
              ...(isMobile && { marginTop: 10 }),
            }}
          ></img>
      </Grid>
</Grid>

</>

        )}
 







        {/* <Grid
          container
          sx={{
            margin: "0 auto",
            paddingTop: isMobile ? 5 : 0,
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="left"
          style={{ backgroundColor: "white", minHeight: 300 }}
          item
          xs={11}
          md={11}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ verticalAlign: "middle", display: "inline-block" }}
          >
            <img
              alt=""
              fetchpriority="high"
              src={HauslaneLogo}
              style={{
                width: "90%",
                maxWidth: isMobile ? "30vw" : "25vw",
                ...(isMobile && { marginTop: 10 }),
                marginBottom: isMobile ? 50 : 0,
              }}
            ></img>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ verticalAlign: "middle", display: "inline-block" }}
          >
            <Typography
              variant="body1"
              item
              xs={12}
              md={5}
              style={{
                margin: "0 auto",
                textAlign: "center",
                fontStyle: "italic",
                fontSize: isMobile ? 9 : 15,
                marginTop: isMobile ? 0 : 40,
                width: "90%",
                maxWidth: isMobile ? "80vw" : "60vw",
              }}
            >
              <span
                style={{
                  color: "black",
                  fontWeight: 600,
                  fontSize: isMobile ? 10 : 20,
                }}
              >
                "
              </span>
              Utilizing the GIGL platform has significantly optimized our
              influencer marketing strategies at Hauslane. It has been
              instrumental in fostering robust brand-creator relationships… and
              noteworthy growth in our social media audience. We highly
              recommend leveraging the GIGL platform for streamlining influencer
              marketing endeavors.
              <span
                style={{
                  color: "black",
                  fontWeight: 600,
                  fontSize: isMobile ? 10 : 20,
                }}
              >
                "
              </span>
            </Typography>

            <Typography
              variant="body1"
              style={{
                marginRight: 0,
                margin: "0 auto",
                textAlign: "center",
                fontStyle: "italic",
                fontSize: 12,
                marginTop: 20,
                width: "90%",
                fontWeight: 600,
                maxWidth: isMobile ? "80vw" : "60vw",
                paddingBottom: 10,
              }}
            >
              - Hauslane Marketing Team
            </Typography>
          </Grid>
        </Grid> */}
      </div>

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "black", minHeight: 10 }}
      ></Grid>
    </>
  );
}
