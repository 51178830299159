
import { Button } from ".";
import launch from "../assets/creatorOutreachAvatar.PNG";
import primaryChannels from "../assets/primaryChannels.PNG";
import creatorRates from "../assets/creatorRates.PNG"
import { parseFollowers, getRateRange } from "../util/helpers";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/system';
import { FavoriteBorderOutlined, Favorite } from "@mui/icons-material";




import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Avatar,
} from "@mui/material";

const LearnMoreDialog = ({ closeDialog, isDialogOpen, creator, isFavorite, handleFavorite }) => {
  const navigate = useNavigate();
  const CustomFavoriteBorderOutlined = styled(FavoriteBorderOutlined)(({ theme }) => ({
    color: "red"
  }));
  const CustomFavorite = styled(Favorite)(({ theme }) => ({
    color: "red"
  }));
  
  return <>
    <Dialog
      open={isDialogOpen}
      onClose={() => closeDialog()}
      PaperProps={{
        style: { borderRadius: 20, minWidth: "80vw", maxHeight:"80vh" },
      }}
    >
      <DialogTitle>
        <Avatar
          fetchPriority="high"
          src={creator.profileImage || launch}
          sx={{ width: 110, height: 110, marginRight: 5, float: "right" }}
        />
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:20}}>
        <h3 style={{ marginTop: 25, float: "left" }}>
          Learn More About {creator?.name}
        </h3>
        {isFavorite && <Button onClick={()=>handleFavorite(!isFavorite.favorite_status)} style={{backgroundColor:"white",color:"black",height:40,fontSize:15}} endIcon={isFavorite.favorite_status?<CustomFavorite/> :<CustomFavoriteBorderOutlined/>}>{`${isFavorite.favorite_status?"Added":"Add"}`} to Favorites</Button>}
        </div>        
      </DialogTitle>
      <DialogContent>
        <Grid
          style={{
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
            minHeight: 5,
            width: 1000,
            marginBottom: 30,
          }}
        ></Grid>

        <Grid style={{ width: "50%", float: "right" }}>
          <Grid container>
            <Grid item xs={3}>
              <Typography gutterBottom variant="h5" component="div">
                {creator?.followers}
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                Followers
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography gutterBottom variant="h5" component="div">
                {creator?.relevance}
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                Relevance
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 4 }}>
            Want to Send a Custom Email?
          </Typography>

          <Typography variant="body2" sx={{ marginBottom: 2, maxWidth: 400 }}>
            Click here to send your customized email. You can invite this
            creator to apply for your campaign so that you can continue your
            journey through the platform. If you would like to use a gigl
            templated email, you can close this field and select Gigl outreach
            in your discovery table.
          </Typography>
          <Grid
            style={{
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
              minHeight: 5,
              width: 420,
            }}
          ></Grid>
          <div style={{ paddingTop: "3em", paddingBottom: "1.5em", gap: 20, display: "flex", flexDirection: "column" }}>
            <Button
              style={{
                borderRadius: 20,
                backgroundColor: "#4158D0",
                backgroundImage:
                  "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
                fontSize: 16,
                width: 420,
                marginTop: 5,
              }}
              href={`mailto:${creator?.email}?subject=Gigl: New Campaign Partnership Opportunity`}
            >
              Send a Custom Email
            </Button>

            <Button
              alignItems="right"
              style={{
                borderRadius: 20,
                backgroundColor: "#4158D0",
                backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                fontSize: 16,
                width: 420

              }}
              rel="noopener noreferrer" target="_blank" href={creator?.link}
            >
              Visit Channel
            </Button>

            <Button
              alignItems="right"
              style={{
                borderRadius: 20,
                backgroundColor: "#4158D0",
                backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                fontSize: 16,
                width: 420

              }}
              onClick={() => { navigate(`/outreach/${creator?.id}`) }}
            >
              View Full Creator Profile
            </Button>

          </div>


          <img
            src={primaryChannels}
            float="left"
            style={{
              height: 50,
              width: 200,
              marginLeft: 100,
              borderRadius: 5,
              objectFit: "cover",

            }}
          />


        </Grid>

        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          About:
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          style={{ float: "left", maxWidth: 400, marginTop: 4 }}
        >
          This creator has built a great community with over{" "}
          {creator?.followers} followers. People follow this creator for{" "}
          {creator?.industry} and more topics. They are currently located in{" "}
          {creator?.location}, and are relevent for {creator?.relevance}{" "}
          campaigns.
        </Typography>

        <br />

        <br />
        <br />
        <br />
        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          Location:
        </Typography>
        <Typography gutterBottom variant="body2">
          {creator?.location}, {creator?.city}
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          Audience Focus:
        </Typography>
        <Typography gutterBottom variant="body2">
          {creator?.industry}
        </Typography>
        <Typography
            gutterBottom
            variant="body2"
            style={{ fontWeight: 600, marginTop: 20 }}
          >
            Predicted Post Views, Powered by AI
          </Typography>
          <Typography gutterBottom variant="body2">
            {creator?.reach} 
          </Typography>

        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          Paid Partnership Rate Range:
        </Typography>

        <Typography gutterBottom variant="body2">
          {getRateRange(parseFollowers(creator?.followers))} per post
        </Typography>


        <br />
        <Typography gutterBottom variant="body2" style={{ marginTop: 20 }}>
        Get the latest creator compensation data:
        </Typography>

        <img
          src={creatorRates}
          // height={"220px"}
          // width={"280px"}
          float="left"
          style={{
            height: 250,
            width: 400,
            borderRadius: 5,
            objectFit: "cover",

          }}
        />

        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { closeDialog() }}>Close</Button>
      </DialogActions>
    </Dialog>
  </>
}

export default LearnMoreDialog;