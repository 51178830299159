import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import Button from "../components/Button";
import { useEffect, useRef } from "react";
import Influencer from "../assets/influencer.jpg";
import Product from "../assets/product.jpg";
import amazingCreators from "../assets/amazing_creators.PNG";
import Sebb from "../assets/sebb_creator.png";
import Thais from "../assets/thais_creator.png";
import Disha from "../assets/disha_creator.png";
import Sahil from "../assets/sahil_creator.png";
import easyDiscovery from "../assets/EasyDiscovery.PNG";

export default function Join({ handleOpenWaitlist }) {
  const imageRef = useRef();
  const isMobile = useMediaQuery("(max-width:800px)");

  const startAnimation = () => {
    if (imageRef.current) {
      const top = imageRef.current.getBoundingClientRect().top;
      const visible = top <= window.innerHeight - 50;
      if (visible) {
        imageRef.current.className = "animate__animated animate__fadeInRight";
        imageRef.current.style.setProperty("--animate-duration", "1.5s");
        imageRef.current.style.setProperty("visibility", "visible");
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      startAnimation();
    });
    startAnimation();
  }, []);
  return (
    <>
          <Grid
        container
        sx={{
          // margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "black", minHeight: 10, }}
      ></Grid>
      <div
        style={{
          display: "block",
          overflowX: "hidden",
          overflowY: "hidden",
          backgroundColor: "#fff0f5",
        }}
      >
        <div
          style={{
            float: isMobile ? "" : "left",
            maxWidth: isMobile ? "100vw" : "50vw",
            marginLeft: isMobile ? 0 : 100,
            textAlign: isMobile ? "center" : "left",
            marginTop: 60,
          }}
        >
          <Typography
            style={{
              fontSize: isMobile ? 15 : 25,
              marginTop: isMobile ? 0 : 40,
            }}
          >
            The Gigl Creator
          </Typography>
          <div
            style={{ marginTop: -10, textAlign: isMobile ? "center" : "left" }}
          >
            <Typography
              style={{
                fontSize: isMobile ? 20 : 45,
                fontWeight: 600,
                marginTop: 20,
              }}
            >
              Built by Creators
            </Typography>
            <Typography
              style={{ fontSize: isMobile ? 20 : 45, fontWeight: 600 }}
            >
              for Creators.
            </Typography>
          </div>
          <p
            style={{
              width: isMobile ? "80vw" : "35vw",
              ...(isMobile && { margin: "0 auto" }),
              fontSize: isMobile ? 10 : 18,
              marginTop: isMobile ? 10 : 10,
              width: isMobile ? "70%" : "70%",
            }}
          >
            We are the trusted platform that puts creators first. Earn income,
            by doing you. Download our app today and swipe through all open
            brand campaigns.
          </p>
          <Button
            style={{
              marginTop: 20,
              marginBottom: isMobile ? 60 : 5,
              fontSize: isMobile ? 14 : 20,
            }}
            href="https://apps.apple.com/us/app/gigl/id6450534178"
            target="_blank"
          >
            Download the Gigl App
          </Button>
        </div>

        <div style={{ position: "relative" }}>
          {!isMobile && (
            <Paper
              elevation={3}
              sx={{
                height: isMobile ? 340 : 500,
                float: isMobile ? "" : "right",
                borderRadius: 10,
                marginBottom: 10,
                width: "35vw",
                marginTop: 10,
              }}
            >
              <img
                src={Influencer}
                height="100%"
                style={{
                  borderRadius: 20,
                  maxHeight: isMobile ? 400 : 500,
                  maxWidth: isMobile ? "40vw" : "",
                  ...(isMobile && { marginTop: 10 }),
                }}
              ></img>
            </Paper>
          )}{" "}
          {!isMobile && (
            <img
              ref={imageRef}
              src={amazingCreators}
              style={{
                borderRadius: 20,
                height: 300,
                position: "absolute",
                zIndex: 1000,
                right: "30vw",
                top: 200,
                visibility: "hidden",
                maxWidth: isMobile ? "19vw" : "25vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          )}{" "}
        </div>
      </div>

      <Grid
        container
        sx={{
          margin: "0 auto",
          paddingTop: isMobile ? 1 : 0,
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "rgb(0 0 0)", minHeight: 60 }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 11 : 20,
            color: "white",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Join Our Community of Thousands of Top Creators
        </Typography>
      </Grid>

      {isMobile ? (
        <Grid>
          <Grid
            item
            xs={11}
            md={5}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <img
              alt=""
              fetchpriority="high"
              src={Sebb}
              style={{
                width: "90%",
                maxWidth: isMobile ? "50vw" : "22vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={Thais}
              style={{
                width: "90%",
                maxWidth: isMobile ? "50vw" : "22vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
          <Grid>
            <img
              alt=""
              fetchpriority="high"
              src={Disha}
              style={{
                width: "90%",
                maxWidth: isMobile ? "50vw" : "22vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={Sahil}
              style={{
                width: "90%",
                maxWidth: isMobile ? "50vw" : "22vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={11}
            md={5}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <img
              alt=""
              fetchpriority="high"
              src={Sebb}
              style={{
                width: "90%",
                maxWidth: isMobile ? "22vw" : "22vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={Thais}
              style={{
                width: "90%",
                maxWidth: isMobile ? "22vw" : "22vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={Disha}
              style={{
                width: "90%",
                maxWidth: isMobile ? "22vw" : "22vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={Sahil}
              style={{
                width: "90%",
                maxWidth: isMobile ? "22vw" : "22vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
        </>
      )}
    </>
  );
}
