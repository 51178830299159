import { useState, useEffect, useContext, useRef } from "react";
import { fetchGet } from "../util/helpers";
import { useParams } from "react-router-dom";
import {
    Typography,
    Grid,
    Avatar,
    Box,
} from "@mui/material";

import launch from "../assets/creatorOutreachAvatar.PNG";
import levelChart from "../assets/CreatorLevels.PNG";
import creatorRates from "../assets/creatorRates.PNG";
import EngRates from "../assets/AvgEngRates.PNG";
import { styled } from '@mui/system';


import {Button, LeftSideBar} from "../components";
import { useNavigate } from "react-router-dom";
import { parseFollowers, getCelebrityRange, getRateRange, fetchPost, fetchPut } from "../util/helpers";
import { FavoriteBorderOutlined, Favorite } from "@mui/icons-material";
import LoadingContext from "../contexts/LoadingContext";


const LearnMoreOutreach = () => {
    const [rightGridHeight, setRightGridHeight] = useState(0);
    const rightGridRef = useRef(null);
    const { creatorId } = useParams();
    const [outreachData, setOutReachData] = useState({});
    const [isFavorite, setIsFavorite] = useState({});
    const CustomFavoriteBorderOutlined = styled(FavoriteBorderOutlined)(({ theme }) => ({
        color: "red"
      }));
      const CustomFavorite = styled(Favorite)(({ theme }) => ({
        color: "red"
      }));
    const { setIsLoading } = useContext(LoadingContext);

    const fetchOutreach = async () => {
        if (creatorId) {
            const response = await fetchGet(`/brand/outreach/${creatorId}`,setIsLoading);
            if (response.result) {
                setOutReachData({ ...response.result, level: getCelebrityRange(parseFollowers(response.result.followers)) });
            }
        }
    };
    const fetchFavorite = async () => {
        if (creatorId) {
            const response = await fetchGet(`/brand/outreach/${creatorId}/favorite`);
            if (response.data) {
                setIsFavorite({ ...response.data});
            }
        }
    }
    const followerCountToNumber = (followers) => {
        if (followers === undefined) {
            return 0
        }
        let amountMultiplier = followers.slice(-1).toLowerCase();
        if (amountMultiplier === "k") {
          amountMultiplier = 1000;
        } else if (amountMultiplier === "m") {
          amountMultiplier = 1000000;
        } else {
          amountMultiplier = 1;
        }
        const amount = parseFloat(followers.slice(0, -1));
        return amount * amountMultiplier;
      };
    useEffect(() => {
        fetchOutreach();
        fetchFavorite();
    }, [creatorId])

    useEffect(() => {
        if (rightGridRef.current) {
            const updateHeight = () => {
                setRightGridHeight(rightGridRef.current.clientHeight);
            };

            updateHeight();

            window.addEventListener('resize', updateHeight);

            return () => {
                window.removeEventListener('resize', updateHeight);
            };
        }
    }, [rightGridRef.current]);

    const handleFavorite = async (status) => {
        if(!isFavorite.id){
          const result = await fetchPost("/brand/outreach/favorite",{
            "outreach_emailid":creatorId,
            "favorite_status":status
          });
          const resultData = {...result.data};
          
          setIsFavorite({...resultData});
        }else{
          const result = await fetchPut(`/brand/outreach/favorite/${isFavorite.id}`,{...isFavorite,"favorite_status":status});
          const resultData = {...result.data};
          
          setIsFavorite({...resultData});
        }
      }
   console.log("outreach data", outreachData)
    return <>
        <div
            style={{
                width: "100%",
                height: "100%",
                background: "black",
                textAlign: "left",
                marginTop: 5,
                color: "white",
                paddingTop: 10,
                paddingBottom: 20,

            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "space-between", width: "85%", alignItems: "center",
            }}>
                <div style={{display:"flex",flexDirection:"row",gap:30,alignItems:"center" }}>
                <Typography
                    variant="h2"
                    component="div"
                    style={{ color: "white", marginLeft: "15vw" }}
                >
                    About {outreachData.name}
                </Typography>
                <Button onClick={()=>handleFavorite(!isFavorite.favorite_status)} style={{backgroundColor:"white",color:"black",height:40,fontSize:15}} endIcon={isFavorite.favorite_status?<CustomFavorite/> :<CustomFavoriteBorderOutlined/>}>{isFavorite.favorite_status? "Added":"Add"} to Favorites</Button>
                </div>
                <Avatar
                    fetchPriority="high"
                    src={outreachData.profileImage || launch}
                    sx={{ width: 100, height: 100, marginRight: 5, float: "left" }}
                />
            </div>

        </div>
        <Grid container>
            <div style={{ width: "90vw" }}>
                <Grid container>
                <Grid item xs={2} style={{ textAlign: "left", height: rightGridHeight }}>
                        <LeftSideBar />
                    </Grid>
                    <Grid item xs={10} style={{ textAlign: "left", marginTop: 50, marginBottom: 20 }} ref={rightGridRef}>

                        <Typography
                            gutterBottom
                            variant="div"
                            style={{ float: "left", marginTop: 4, backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6 }}
                        >
                            <span variant="h6" style={{ fontWeight: "bold" }}> Summary </span>
                            <br/>
                            This creator has built a great community with over {outreachData.followers} followers. People follow this creator for {outreachData.industry} and more topics. They are currently located in {outreachData.location}, and are relevent for {outreachData.relevance}{" "} 
                            campaigns. Want to partner with this creator? Launch a campaign and invite them to partner, or send them a custom email below. 
                        </Typography>
                        <Grid container>
                            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: "1em", marginTop: 10, }}>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Creator Location:
                                    </Typography>

                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.location}
                                    </Typography>
                                </div>
                                
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Audience Focus:
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.industry}, {outreachData.audienceTier}
                                    </Typography>
                                </div>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Content Relevance
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.relevance} Campaigns
                                    </Typography>
                                </div>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Followers:
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.followers}
                                    </Typography>
                                </div>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Creator Level:
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.level}
                                    </Typography>
                                </div>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Age Bracket:
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.ageBracket}
                                    </Typography>
                                </div>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Additional Details:
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.gender}, {outreachData.ethnicity}
                                    </Typography>
                                </div>

                                <div style={{marginBottom: 8}}>

                                <Typography
                                    variant="h7"
                                    style={{ fontWeight: "bold", marginBottom: 10 }}
                                    gutterBottom
                                >
                                    Check out their channel:
                                </Typography>
                                <Button
                                    alignItems="right"
                                    style={{
                                        borderRadius: 20,
                                        backgroundColor: "#4158D0",
                                        backgroundImage:
                                            "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                                        fontSize: 16,
                                        width: 420,
                                        color: "white",
                                        marginBottom: 35,
                                        marginTop: 10
                                    }}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={outreachData.link}
                                >
                                    Visit Channel
                                </Button>
                                </div>

                            </Grid>
                            <Grid item xs={6}  sx={{ marginTop: 5 }}> 
                                <img
                                    alt=""
                                    fetchpriority="high"
                                    src={levelChart}
                                    style={{
                                        maxWidth: "80%",
                                        maxHeight: "80%",
                                        marginLeft: 110
                                    }}
                                ></img>
                            </Grid>

                        </Grid>
                        <Grid
                            style={{
                                minHeight: 5,
                                backgroundColor: "#4158D0",
                                backgroundImage:
                                    "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
                                width: "100%",
                                marginBottom: 30,
                            }}
                        ></Grid>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                            gutterBottom
                        >
                            Compensation Details
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{paddingTop: "1em" }}
                        >
                            Paid Partnership Rate Range: {getRateRange(parseFollowers(outreachData.followers))} per post
                        </Typography>
                        <Typography
                            gutterBottom
                            variant="div"
                            style={{ float: "left", marginTop: 4, paddingTop: "1em" }}
                        >
                            The industry average compensation based on this creators follower level is within this range. Please note that this range is subject to change based on the creator and their unique collaboration rates. Outreach to this creator to identify their latest content rates. The numbers outlined are in dollar values.
                        </Typography>
                        <Grid container sx={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                            <Grid item xs={6} sx={{ height: "100%" }}>
                                <Typography
                                    gutterBottom
                                    variant="body1"
                                    style={{ float: "left", padding: "6em", backgroundColor: "#F9F2FD", borderRadius: 6, }}
                                >
                                    Make informed budget decisions with the latest creator compensation data
                                    <br/>
                                    <span style={{ fontSize: 12}}>*Measured in USD</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    alt=""
                                    fetchpriority="high"
                                    src={creatorRates}
                                    style={{
                                        maxWidth: "95%",
                                    }}
                                ></img>
                            </Grid>

                        </Grid>
                        <Grid
                            style={{
                                minHeight: 5,
                                backgroundColor: "#4158D0",
                                backgroundImage:
                                    "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
                                width: "100%",
                                marginBottom: 35
                            }}
                        ></Grid>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: "bold", marginBottom: 15 }}
                            gutterBottom
                        >
                            Creator Outreach Details
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="div"
                            style={{ float: "left", marginTop: 4, backgroundColor: "#F9F2FD", padding: 10, borderRadius: 6, marginBottom: 20 }}
                        >
                            Gigl helps you connect with your favorite creators! See how to connect with this creator below. You can send them a custom email 
                            or you can launch a campaign and directly invite them to participate in your campaign. You can also use Gigl's templated email outreach, 
                            available on you campaign outreach table.
                        </Typography>
                        <Typography
                            variant="h6"
                            gutterBottom
                        >
                            Send a custom email:
                        </Typography>

                        <Button
                            style={{
                                borderRadius: 20,
                                backgroundColor: "#4158D0",
                                backgroundImage:
                                    "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
                                fontSize: 16,
                                width: 420,
                                marginTop: 5,
                                color: "white",
                                marginBottom: 35

                            }}
                            href={`mailto:${outreachData.email}?subject=Gigl: New Campaign Partnership Opportunity`}
                        >
                            Send a Custom Email
                        </Button>
                        <Typography
                            variant="h6"
                            gutterBottom
                        >
                            Check out their channel:
                        </Typography>
                        <Button
                            alignItems="right"
                            style={{
                                borderRadius: 20,
                                backgroundColor: "#4158D0",
                                backgroundImage:
                                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                                fontSize: 16,
                                width: 420,
                                color: "white",
                                marginBottom: 35
                            }}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={outreachData.link}
                        >
                            Visit Channel
                        </Button>

                        <Grid
                            style={{
                                minHeight: 5,
                                backgroundColor: "#4158D0",
                                backgroundImage:
                                    "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
                                width: "100%",
                                marginBottom: 30,
                            }}
                        ></Grid>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                            gutterBottom
                        >
                            Predicted Performance, Powered by AI
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="div"
                            style={{ float: "left", marginTop: 4, marginBottom: 20 }}
                        >
                            Forecast performance of this creator's next post based on their most recent performance history. 
                        </Typography>
                     <Grid container>
                        <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: "1em", marginTop: 10, }}>
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Predicted Post Views:
                                    </Typography>

                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.reach}
                                    </Typography>
                                </div>

                                <div style={{marginBottom: 8}}>

                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Predicted Engagement Rate: 
                                    </Typography>

                                {outreachData && followerCountToNumber(outreachData.followers) <= 50000 && (
                                        <Typography variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}
                                        >3.1% </Typography>
                                )}

                                 {outreachData && followerCountToNumber(outreachData.followers) > 50000  && followerCountToNumber(outreachData.followers) <= 150000 && (
                                        <Typography variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}>
                                            2.7% </Typography>
                                )}
                                {outreachData && followerCountToNumber(outreachData.followers)> 150000  && followerCountToNumber(outreachData.followers) <= 500000 && (
                                        <Typography variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}>
                                            2.3% </Typography>
                                )}
                                {outreachData && followerCountToNumber(outreachData.followers) > 500000  && followerCountToNumber(outreachData.followers) <= 1000000 && (
                                        <Typography variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}>
                                            2% </Typography>
                                )}
                                {outreachData && followerCountToNumber(outreachData.followers) > 1000000  && followerCountToNumber(outreachData.followers) <= 1500000 && (
                                        <Typography variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}>
                                            1.89% </Typography>
                                )}
                               {outreachData && followerCountToNumber(outreachData.followers)> 1500000 && (
                                        <Typography variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}
                                        >1.5% </Typography>
                                )}
                                </div>

                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Follower Growth:
                                    </Typography>
                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}
                                    >
                                        Steady
                                    </Typography>
                                </div>
                                
                                <div style={{marginBottom: 8}}>
                                    <Typography
                                        variant="h7"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Predicted Content Audience Industries:
                                        <br/>
                                        <br/>

                                    </Typography>
                                    <Typography
                                        variant="body"
                                        gutterBottom
                                        style={{ backgroundColor: "#e0ffff", padding: 10, borderRadius: 6 }}
                                    >
                                        {outreachData.industry}
                                    </Typography>
                                </div>

                            </Grid>   
                            <Grid item xs={6}  sx={{ marginTop: 3 }}> 
                                <img
                                    alt=""
                                    fetchpriority="high"
                                    src={EngRates}
                                    style={{
                                        maxWidth: "90%",
                                        maxHeight: "90%",
                                        marginLeft: 40
                                    }}
                                ></img>
                            </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    </>
}

export default LearnMoreOutreach;