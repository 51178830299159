import {
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";

const LeftSideBar = () => {
  const navigate = useNavigate();

  return <>
    <Box
      sx={{
        textAlign: "center",
        boxShadow: 1,
        width: "12vw",
        backgroundColor: "#f5f5f5",
        height: "110%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
      alignItems="left"
    >
      <div style={{ gap: 15, display: "flex", flexDirection: "column", marginTop: 50, }}>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns")}
        >
          My Dashboard
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/blog")}
        >
          Blog & Insights
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns/analyticsdashboard")}
        >
          Analytics
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns/creatordiscovery")}
        >
          Creator Discovery
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/competitive/landscape")}
        >
          Landscape & Trends
        </Button>
        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/favorites")}
        >
          My Favorites
        </Button>
      </div>
      <div style={{ gap: 15, display: "flex", flexDirection: "column", marginBottom: 50}}>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns/FAQ")}
        >
          Help & FAQ
        </Button>

        <Button
          style={{
            borderRadius: 1,
            width: "100%",
            fontSize: 12,
          }}
          onClick={() => navigate("/campaigns/account")}
        >
          My Account
        </Button>
      </div>
    </Box>
  </>

}

export default LeftSideBar;