import How from "./How";
import Join from "./Join";
import Main from "./Main";
import { Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../util/firebase";
import toast, { Toaster } from "react-hot-toast";
import Button from "../components/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Home.css";
import { useNavigate } from "react-router-dom";

const DEFAULT_MODAL_TEXT =
  "To subscribe to our waiting list and get first access to our launch in June, please fill out the below details!";
export default function Home() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [modalText, setModalText] = useState(DEFAULT_MODAL_TEXT);
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();

  const handleOpenWaitlist = (e, text = DEFAULT_MODAL_TEXT) => {
    setOpen(true);
    setModalText(text);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubscribe = async () => {
    try {
      await addDoc(collection(db, "waitinglist"), {
        email: email,
        type: type,
        submittedAt: new Date(),
      });
      handleClose();
      toast.success("We'll be in touch soon!");
    } catch (err) {
      toast.error("Something went wrong, please try again later");
    }
  };
  console.log("mdao", modalText);
  return (
    <div className="gradient-animation">
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} />
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogTitle>Join Our Waiting List</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
          />

          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel id="type-select-label">
              What best describes you?
            </InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={type}
              variant="standard"
              label="What best describes you?"
              fullWidth
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value={"Influencer"}>Influencer</MenuItem>
              <MenuItem value={"Brand"}>Brand</MenuItem>
              <MenuItem value={"Agency"}>Agency</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubscribe}
            sx={{
              background:
                "linear-gradient(130deg, white 0%, #fccb90 25%, #d57eeb 75%, #7b79be 100%)",
            }}
          >
            Join Now
          </Button>
        </DialogActions>
      </Dialog>
      <Main handleOpenWaitlist={handleOpenWaitlist} />
      <How handleOpenWaitlist={handleOpenWaitlist} />
      <Join handleOpenWaitlist={handleOpenWaitlist} />
      <div
        style={{ height: 5, width: "100vw", backgroundColor: "black" }}
      ></div>
      <Grid
        container
        spacing={1}
        sx={{
          width: isMobile ? "100vw" : "80vw",
          margin: "0 auto",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <div
            style={{ display: isMobile ? "" : "flex", alignItems: "center" }}
          >
            <Typography variant="h3"> GIGL</Typography>
          </div>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/privacy")}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/terms")}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/limited-use")}
          >
            Limited Use
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          {isMobile ? (
            <p style={{ fontSize: 9 }}>Toronto, Ontario, Canada</p>
          ) : (
            <>
              {" "}
              <p style={{ fontSize: 9 }}>Toronto</p>
              <p style={{ fontSize: 9 }}>Ontario, Canada</p>
              <p style={{ fontSize: 9 }}>giglgroup.com</p>
            </>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <p style={{ fontSize: 9, marginRight: 15, marginLeft: 15 }}>
            Contact Us: support@giglgroup.com
          </p>
          <p style={{ fontSize: 8, marginRight: 15, marginLeft: 15 }}>
            Copyright @ Gigl 2023.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
