import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Paper,
  CardHeader,
  Avatar,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Alert,
  Box,
  TextField,
  Skeleton,
} from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { Line, Bar } from "react-chartjs-2";
import instagram from "../assets/ig.png";
import tiktok from "../assets/tiktok.png";
import yt from "../assets/yt.png";
import twitter from "../assets/twitter.png";
import "chart.js/auto"; // ADD THIS
import { fetchGet, fetchPost, formatStat } from "../util/helpers";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import CreatorCard from "./CreatorCard";
import { INDUSTRIES } from "../util/constants";
import CampaignDialog from "./CampaignDialog";
import { Edit } from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import GroupIcon from "@mui/icons-material/Group";
import OutreachTable from "./OutreachTable";
import Pagination from "@mui/material/Pagination";
import MoreCreators from "../assets/moreCreators.PNG";
import followerGrowth from "../assets/followerGrowth.PNG";
import RecommendationCard from "./RecommendationCard";
import toast, { Toaster } from "react-hot-toast";
import multipleCreators from "../assets/multiplecreators.PNG";


function getLastWeek(week = 1) {
  var today = new Date();
  var lastWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7 * week,
  );
  return lastWeek;
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function Campaign() {
  const [campaign, setCampaign] = useState({});
  const [briefModalOpen, setBriefModalOpen] = useState(false);
  const [briefURL, setBriefURL] = useState("");
  const [creatorDialogOpen, setCreatorDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState({});
  const [creators, setCreators] = useState([]);
  const [bestMatchCreators, setBestMatchCreators] = useState([]);
  const [matches, setMatches] = useState([]);

  const [hasMatch, setHasMatch] = useState(false);
  const [creatorLevel, setCreatorLevel] = useState("any");
  const [creatorIndustry, setCreatorIndustry] = useState("any");
  const { campaignId } = useParams();
  const [activeDialogOpen, setActiveDialogOpen] = useState(false);
  const [gettingStartedgOpen, setGettingStartedOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { setIsLoading, setLoadingText } = useContext(LoadingContext);
  const [analytics, setAnalytics] = useState([]);
  const [creatorPageIndex, setCreatePageIndex] = useState(1);
  const [briefLoaded, setBriefLoaded] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const briefInterval = useRef();
  const iframeRef = useRef(null);
  const navigate = useNavigate();

  const clearCheckingInterval = () => {
    clearInterval(briefInterval.current);
  };

  const onIframeLoaded = () => {
    console.log("brief loaded");
    clearCheckingInterval();
    setBriefLoaded(true);
  };

  useEffect(() => {
    console.log("pre set interval");
    briefInterval.current = setInterval(() => {
      try {
        console.log("interval running");
        // google docs page is blank (204), hence we need to reload the iframe.
        if (iframeRef.current.contentWindow.document.body.innerHTML === "") {
          iframeRef.current.src =
            "https://docs.google.com/viewer?url=" +
            encodeURIComponent(briefURL) +
            "&embedded=true";
        }
      } catch (e) {
        // google docs page is being loaded, but will throw CORS error.
        // it mean that the page won't be blank and we can remove the checking interval.

        onIframeLoaded();
      }
    }, 4000); // 4000ms is reasonable time to load 2MB document

    return () => clearCheckingInterval();
  }, [briefURL, briefModalOpen]);
  const fetchCampaign = async () => {
    if (campaignId) {
      console.log("id", campaignId);
      const response = await fetchGet(`/brand/campaigns/${campaignId}`);
      if (response.result) {
        setCampaign(response.result);
      }
    }
  };
  const fetchCreators = async () => {
    setIsLoading(true);
    console.log("fetching creators");
    if (campaignId) {
      console.log("id", campaignId);
      const response = await fetchGet(
        `/brand/campaigns/${campaignId}/responses`,
      );
      if (response.result) {
        const creatorTemp = [...response.result];
        creatorTemp.sort(
          (a, b) =>
            (b.stats ? b.stats.totalFollowers : 0) -
            (a.stats ? a.stats.totalFollowers : 0),
        );
        if (
          creatorTemp.length > 0 &&
          creatorTemp[0].stats &&
          creatorTemp[0].stats.totalFollowers > 100000
        ) {
          setBestMatchCreators(creatorTemp.slice(0, 6));
        }
        setCreators(response.result);
      }
      setIsLoading(false);
    }
  };
  const fetchBestMatchCreators = async () => {
    if (campaignId) {
      console.log("id", campaignId);
      const response = await fetchGet(
        `/brand/campaigns/${campaignId}/best_match`,
      );
      if (response.result) {
        // setBestMatchCreators(response.result);
      }
    }
  };

  const fetchMatches = async () => {
    if (campaignId) {
      console.log("id", campaignId);
      const response = await fetchGet(
        `/brand/campaigns/${campaignId}/matches`
      );
      if (response.result) {
        setMatches(response.result);
      }
    }
  };
  const fetchRecommendations = async () => {
    if (campaignId) {
      const response = await fetchGet(
        `/brand/campaigns/${campaignId}/recommendations`,
      );
      if (response.result) {
        setRecommendations(response.result);
      }
    }
  }; 

  useEffect(() => {
    setBriefURL("");
    fetchCampaign();
    fetchCreators();
    fetchBestMatchCreators();
    fetchMatches();
    fetchAnalytics()
    fetchRecommendations();
  }, [campaignId]);

  useEffect(() => {
    setHasMatch(
      creators.some((creator) => creator.response.status === "matched"),
    );
  }, [creators]);

  console.log("campaign", campaign);

  const toggleActivate = async () => {
    await fetchPost(
      `/brand/campaigns/${campaignId}/active`,
      { active: !campaign.active },
      setIsLoading,
    );
    setActiveDialogOpen(false);
    await fetchCampaign();
  };
  const FIELD_TO_DISPLAY = [
    { key: "description", title: "Campaign Description" },
    { key: "deliverables", title: "Deliverables" },
    { key: "talking_points", title: "Talking Points" },
    ,
  ];

  const viewDataLabels = [
    getLastWeek(4),
    getLastWeek(3),
    getLastWeek(2),
    getLastWeek(1),
  ].map((date) => date.toISOString().slice(0, 10));
  const likeDataLabels = ["Like", "Dislike"];

  const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

  const randomRGB = () => {
    const codes = `${randomNum()}, ${randomNum()}, ${randomNum()}`;
    return [`rgb(0, 0, 0)`, `rgba(0,0,0, 0.5)`];
  };

  const reachColor = randomRGB();
  const engagementColor = randomRGB();
  const impressionsColor = randomRGB();
  const reachData = useMemo(
    () => ({
      labels: analytics.map((a) => a.date),
      datasets: [
        {
          label: "Reach",
          data: analytics.map((a) => a.views),
          borderColor: reachColor[0],
          backgroundColor: reachColor[1],
        },
      ],
    }),
    [analytics],
  );

  const engagementData = useMemo(
    () => ({
      labels: analytics.map((a) => a.date),
      datasets: [
        {
          label: "Reach",
          data: analytics.map((a) => a.engagement),
          borderColor: engagementColor[0],
          backgroundColor: engagementColor[1],
        },
      ],
    }),
    [analytics],
  );

  const impressionsData = {
    labels: viewDataLabels,
    datasets: [
      {
        label: "Impressions",
        data: viewDataLabels.map(() => getRandomInt(100, 1000)),
        borderColor: impressionsColor[0],
        backgroundColor: impressionsColor[1],
      },
    ],
  };
  const likeData = {
    labels: likeDataLabels,
    datasets: [
      {
        label: "Likes",
        data: likeDataLabels.map(() => getRandomInt(100, 1000)),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const handleCardClick = (creator) => {
    setSelectedCreator(creator);
    setCreatorDialogOpen(true);
  };

  const generateBrief = async () => {
    setLoadingText("Generating brief");
    fetchPost(
      "/brand/brief/generate",
      {
        campaignId: campaignId,
        brandName: campaign.brand,
        campaignName: campaign.name,
        campaignDeliverables: campaign.deliverables,
        campaignDetails: campaign.description,
        campaignTalkingPoints: campaign.talking_points,
        campaignWrittenTimeline: campaign.written_due,
        campaignDraftTimeline: campaign.draft_due,
        campaignFinalTimeline: campaign.final_due,
      },
      setIsLoading,
    )
      .then(() => {
        const storage = getStorage();
        getDownloadURL(ref(storage, `briefs/${campaignId}.docx`)).then(
          (url) => {
            console.log("url", url);
            setBriefURL(url);
            setBriefModalOpen(true);
          },
        );
      })
      .catch((err) => {
        console.log("error", err);
        alert("An error occured generating the brief");
      });
  };

  const handleConfirmPartnership = async () => {
    if (
      selectedCreator.response &&
      selectedCreator.response.status === "matched"
    ) {
      return;
    }
    await fetchPost(`/brand/campaigns/${campaignId}/matches`, {
      creatorUserDocId: selectedCreator.userDocId,
      responseId: selectedCreator.response.id,
    });
    setSelectedCreator((creator) => {
      creator.response.status = "matched";
      return creator;
    });
    await fetchCreators();
  };
  const fetchAnalytics = async () => {
    const result = await fetchGet(`/brand/campaigns/${campaignId}/analytics`);
    result.result.sort((a, b) => new Date(a.date) - new Date(b.date));
    const dateToData = {};
    result.result.forEach((data) => {
      if (dateToData[data.date] === undefined) {
        dateToData[data.date] = {
          engagement: 0,
          views: 0,
        };
      }
      dateToData[data.date].engagement += data.engagement;
      dateToData[data.date].views += data.views;
    });
    const formattedResult = Object.keys(dateToData).map((date) => {
      return {
        date: date,
        ...dateToData[date],
      };
    });
    setAnalytics(formattedResult);
  };
  console.log("analytics", analytics);
  const totalViews = useMemo(() => {
    return Math.max(...analytics.map((a) => a.views));
  }, [analytics]);

  const totalEngagement = useMemo(() => {
    return Math.max(...analytics.map((a) => a.engagement));
  }, [analytics]);

  const displayedCreators = useMemo(() => {
    return creators.filter((creator) => {
      if (creatorIndustry && creatorIndustry !== "any") {
        if (
          creator.industries &&
          creator.industries.indexOf(creatorIndustry) === -1
        ) {
          return false;
        }
      }
      if (creatorLevel) {
        if (
          creatorLevel === "micro" &&
          creator.stats &&
          creator.stats.totalFollowers <= 50000
        ) {
          return true;
        } else if (
          creatorLevel === "major" &&
          creator.stats &&
          creator.stats.totalFollowers > 50000 &&
          creator.stats.totalFollowers < 150000
        ) {
          return true;
        } else if (
          creatorLevel === "celebrity" &&
          creator.stats &&
          creator.stats.totalFollowers > 150000
        ) {
          return true;
        } else if (creatorLevel === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    })
  }, [creatorIndustry, creatorLevel, creators])

  const copyInviteLink = async ()=>{
    try{
      const invitationLink = `${window.location.origin}/match/${campaignId}`; 
      await navigator.clipboard.writeText(invitationLink);
      toast.success("Link copied!")
    }catch(e){
      toast.error("Failed to copy link!");
    }
  }

  return (
    <>
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} />

      <CampaignDialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        campaign={campaign}
        onSubmit={() => fetchCampaign()}
      />
      <Dialog
        open={activeDialogOpen}
        fullWidth
        onClose={() => setActiveDialogOpen(false)}
      >
        <DialogTitle>
          {campaign.active ? "Hide Campaign" : "Open Your Campaign for Applications"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {campaign.active
              ? "Your campaign will no longer be visible to creators anymore. Creators won't be able to discover your campaign through our app, however this will not affect creators you are already matched with."
              : "You are making your campaign open and visible for applications. Creators on Gigl will be able to view your campaign and apply to work with you."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setActiveDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              toggleActivate();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={gettingStartedgOpen}
        fullWidth
        onClose={() => setGettingStartedOpen(false)}
        PaperProps={{
          style: { borderRadius: 20, minWidth: "80vw", maxHeight:"80vh" },
        }}
      >
        <DialogTitle>Getting Started on Your Campaign</DialogTitle>

        <DialogContent>
          <Grid
            style={{
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
              minHeight: 5,
              width: 890,
              marginBottom: 30,
            }}
          ></Grid>

          <Typography style={{ fontSize: 16, fontweight: 1000 }}>
            1. Receiving Creator Applications:
          </Typography>
          <Typography style={{ fontSize: 14 }}>
            In order to receive applications for your campaign you must click
            the "Make Public" Button at the top right corner of your campaigns
            page. Don't worry this is campaign is internal to Gigl. Make your
            campaign hidden if you are still in the planning phase and editing
            you campaign.
          </Typography>
          <br />
          <br />

          <Typography style={{ fontSize: 16, fontweight: 1000 }}>
            2. Identifying New Creators:
          </Typography>
          <Typography style={{ fontSize: 14 }}>
            Identify new creators for your campaign in the Discovery Section
            below. Easily filter to find your perfect match, and directly
            outreach to invite new creators to apply to work with you.
          </Typography>

          <br />
          <br />
          <Typography style={{ fontSize: 16, fontweight: 1000 }}>
            3. Working with Creators:
          </Typography>
          <Typography style={{ fontSize: 14 }}>
            Once you confirm your partnership with a creator, you will unlock
            your creator workstream section, where you can easily collaborate
            with each creator for your campaign.
          </Typography>

          <br />
          <br />
          <Typography style={{ fontSize: 16, fontweight: 1000 }}>
            3. Tips to get more creators interested in working with you:
          </Typography>
          <br />
          <Typography style={{ fontSize: 14 }}>
            a. Keep your campaign description short and sweet (1-3 sentences):
            Example: Our Brand is [X], we are looking for [Z] type of creators
            to join our (paid,affiliate,merch) campaign, and we want to get
            viewers excited for this new launch.
            <br />
            <br />
            b. Don't include any links to documents/briefs/contracts at this
            point. Share after you have confirmed the partnership.
            <br />
            <br />
            c. Have clear deliverables. Example: We are looking for 1 IG video,
            1 static post, and 2 stories.
            <br />
            <br />
            d. Its better to have clear compensation. This will bring relevant
            creators to your campaign
            <br />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/campaigns/FAQ")}>Visit FAQ</Button>
          <Button
            onClick={() => {
              setGettingStartedOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={briefModalOpen}
        fullWidth
        onClose={() => setBriefModalOpen(false)}
        key={`dialog-${briefURL}`}
      >
        <DialogTitle>Generated Brief</DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: 20 }}>
            <a href={briefURL} rel="noopener noreferrer" target="_blank">
              Download Your Modifiable Brief
            </a>
          </Typography>
          {!briefLoaded && (
            <Skeleton
              width="100%"
              height={600}
              variant="rectangular"
              animation="wave"
            />
          )}
          <iframe
            ref={iframeRef}
            title="file"
            width="100%"
            height="600"
            onLoad={onIframeLoaded}
            src={
              "https://docs.google.com/viewer?url=" +
              encodeURIComponent(briefURL) +
              "&embedded=true"
            }
          ></iframe>
        </DialogContent>
      </Dialog>

      <Dialog
        open={creatorDialogOpen}
        fullWidth
        onClose={() => setCreatorDialogOpen(false)}
        PaperProps={{
          style: { borderRadius: 20, minWidth: 1000, minHeight: 700 },
        }}
      >
        <DialogTitle>
          <Avatar
            src={selectedCreator.images && selectedCreator.images[0]}
            sx={{ width: 80, height: 80, marginRight: 2, float: "left" }}
          />
          <h3 style={{ marginTop: 25, float: "left" }}>
            {selectedCreator.name}
          </h3>
          <h3 style={{ marginTop: 25, width: "50%", float: "right" }}>
            Metrics
          </h3>
        </DialogTitle>

        <DialogContent>
          {selectedCreator.predicted && (
            <Grid style={{ width: "50%", float: "right" }}>
              <Grid container>
                <Grid item xs={3}>
                  <Typography gutterBottom variant="h5" component="div">
                    {selectedCreator.stats &&
                      formatStat(selectedCreator.stats.totalFollowers)}
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    Followers
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom variant="h5" component="div">
                    {selectedCreator.stats &&
                      formatStat(selectedCreator.stats.totalPosts)}{" "}
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    Posts
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom variant="h5" component="div">
                    {selectedCreator.stats &&
                      formatStat(selectedCreator.stats.totalReach)}
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    Reach
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 4 }}>
                AI Predicted Metrics
              </Typography>

              <Typography
                variant="body2"
                sx={{ marginBottom: 2, maxWidth: 400 }}
              >
                Forecast performance of this creator's next post based on their
                most recent performance history
              </Typography>
              <Grid
                style={{
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
                  minHeight: 5,
                  width: 420,
                }}
              ></Grid>

              <br />

              <Grid sx={{ display: "inline-flex" }}>
                {" "}
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  Predicted Reach:{" "}
                  <span style={{ fontWeight: 600 }}>
                    {formatStat(selectedCreator.predicted.reach)}{" "}
                  </span>
                </Typography>
                <GroupIcon />
              </Grid>
              <br />

              <Grid sx={{ display: "inline-flex", marginTop: 1, marginTop: 3 }}>
                {" "}
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  Predicted Engagement:{" "}
                  <span style={{ fontWeight: 600 }}>
                    {" "}
                    {formatStat(selectedCreator.predicted.engagement)}{" "}
                  </span>
                </Typography>
                <CommentIcon />
              </Grid>
              <br />

              <Grid sx={{ display: "inline-flex", marginTop: 1, marginTop: 3 }}>
                {" "}
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  Predicted Engagement Rate:{" "}
                  <span style={{ fontWeight: 600 }}>
                    {" "}
                    {Math.round(
                      (parseInt(
                        formatStat(selectedCreator.predicted.engagement),
                      ) /
                        parseInt(formatStat(selectedCreator.predicted.reach))) *
                        10,
                    ) / 10}{" "}
                    %{" "}
                  </span>
                </Typography>
                <CommentIcon />
              </Grid>
              <br />
              <br />

              <img
                src={followerGrowth}
                // height={"220px"}
                // width={"280px"}
                float="right"
                style={{
                  height: 220,
                  width: 400,
                  borderRadius: 5,
                  objectFit: "cover",
                  marginLeft: 20,
                }}
              />
            </Grid>
          )}
          <Grid>
            {selectedCreator.response &&
            selectedCreator.predicted.reach === 0 ? (
              <Typography
                gutterBottom
                variant="body2"
                style={{ marginTop: 20, maxWidth: 400, color: "red" }}
              >
                *This creator is being updated into our system: Metrics are
                currently unavailable. In the interim, visit the Discovery
                Section to find their details.
              </Typography>
            ) : (
              <></>
            )}

            <Typography
              gutterBottom
              variant="body2"
              style={{ fontWeight: 600, marginTop: 20 }}
            >
              Audience Focus:
            </Typography>
            <Typography gutterBottom variant="body2">
              {selectedCreator.response &&
                selectedCreator.industries &&
                (selectedCreator.industries.length > 3
                  ? selectedCreator.industries.slice(0, 3).join(", ")
                  : selectedCreator.industries.join(", "))}
            </Typography>
            {selectedCreator.response && selectedCreator.description && (
              <>
                <Typography
                  gutterBottom
                  variant="body2"
                  style={{ fontWeight: 600, marginTop: 20 }}
                >
                  About:
                </Typography>

                <Typography
                  gutterBottom
                  variant="body2"
                  style={{ maxWidth: 400, marginTop: 4, marginBottom: 5 }}
                >
                  {selectedCreator.description}
                </Typography>
              </>
            )}

            <Typography
              gutterBottom
              variant="body2"
              style={{ fontWeight: 600, marginTop: 20 }}
            >
              Paid Partnership Rate Range:
            </Typography>

            {selectedCreator.response &&
              selectedCreator.stats.totalFollowers < 10000 && (
                <Typography gutterBottom variant="body2">
                  $100-$500 per post
                </Typography>
              )}
            {selectedCreator.response &&
              selectedCreator.stats.totalFollowers > 10000 &&
              selectedCreator.stats.totalFollowers < 50000 && (
                <Typography gutterBottom variant="body2">
                  $1000-$2500 per post
                </Typography>
              )}
            {selectedCreator.response &&
              selectedCreator.stats.totalFollowers > 50000 &&
              selectedCreator.stats.totalFollowers < 100000 && (
                <Typography gutterBottom variant="body2">
                  $2000-$3000 per post
                </Typography>
              )}
            {selectedCreator.response &&
              selectedCreator.stats.totalFollowers > 100000 &&
              selectedCreator.stats.totalFollowers < 500000 && (
                <Typography gutterBottom variant="body2">
                  $3000-$5000 per post
                </Typography>
              )}
            {selectedCreator.response &&
              selectedCreator.stats.totalFollowers > 500000 &&
              selectedCreator.stats.totalFollowers < 1000000 && (
                <Typography gutterBottom variant="body2">
                  $5000-$7000 per post
                </Typography>
              )}
            {selectedCreator.response &&
              selectedCreator.stats.totalFollowers > 1000000 &&
              selectedCreator.stats.totalFollowers < 1500000 && (
                <Typography gutterBottom variant="body2">
                  $6000-$9000 per post
                </Typography>
              )}
            {selectedCreator.response &&
              selectedCreator.stats.totalFollowers > 1500000 && (
                <Typography gutterBottom variant="body2">
                  $10000+ per post
                </Typography>
              )}

            <Typography
              gutterBottom
              variant="body2"
              style={{ fontWeight: 600, marginTop: 20 }}
            >
              Content Preview:
            </Typography>

            <div>
              {selectedCreator.images &&
                selectedCreator.images.map((img, index) => (
                  <img
                    src={img}
                    height={"100px"}
                    width={"100px"}
                    style={{
                      marginRight:
                        index === selectedCreator.images.length - 1
                          ? 0
                          : "20px",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                ))}
            </div>

            <br />
            <br />

            <Grid style={{ float: "left" }}>
              {selectedCreator.response &&
                selectedCreator.response.status !== "matched" && (
                  <Button
                    style={{
                      backgroundColor: "#4158D0",
                      backgroundImage:
                        "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                      textAlign: "center",
                      marginTop: 30,
                      float: "left",
                    }}
                    onClick={handleConfirmPartnership}
                  >
                    {" "}
                    Confirm Partnership
                  </Button>
                )}

              <br />
              <br />

              {selectedCreator.response &&
                selectedCreator.response.status === "matched" && (
                  <Button
                    style={{
                      textAlign: "center",
                      marginTop: 30,
                      float: "left",
                    }}
                    onClick={() =>
                      navigate(`/campaigns/${campaignId}/workstream`)
                    }
                  >
                    Visit Creator Workstream
                  </Button>
                )}
            </Grid>

            {selectedCreator.tiktok && (
              <IconButton
                sx={{ width: 50, height: 50 }}
                href={selectedCreator.tiktok}
              >
                <img
                  src={tiktok}
                  style={{ objectFit: "contain" }}
                  height="90%"
                ></img>
              </IconButton>
            )}
            {selectedCreator.youtube && (
              <IconButton
                sx={{ width: 50, height: 50 }}
                href={selectedCreator.youtube}
              >
                <img
                  src={yt}
                  style={{ objectFit: "contain" }}
                  height="90%"
                ></img>
              </IconButton>
            )}
            {selectedCreator.instagram && (
              <IconButton
                sx={{ width: 50, height: 50 }}
                href={selectedCreator.instagram}
              >
                <img
                  src={instagram}
                  style={{ objectFit: "contain" }}
                  height="90%"
                ></img>
              </IconButton>
            )}
            {selectedCreator.twitter && (
              <IconButton
                sx={{ width: 50, height: 50 }}
                href={selectedCreator.twitter}
              >
                <img
                  src={twitter}
                  style={{ objectFit: "contain" }}
                  height="90%"
                ></img>
              </IconButton>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "black",
          textAlign: "left",
          marginTop: 100,
          color: "white",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Typography
          variant="h2"
          component="div"
          style={{ color: "white", marginLeft: "10vw" }}
        >
          {campaign.brand}
        </Typography>
      </div>

      <div
        style={{
          marginTop: 20,
          width: "80vw",
          marginLeft: "10vw",
          marginBottom: 50,
        }}
      >
        <Button
          style={{
            display: "inline-block",
            float: "right",
            marginLeft: 10,
          }}
          onClick={() => setActiveDialogOpen(true)}
        >
          {campaign.active ? "Make Hidden" : "Make Visible for Applications"}
        </Button>
        <Button
          style={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "0",
            marginTop: 10,
            display: "flex",
          }}
          onClick={() => setEditOpen(true)}
          endIcon={<Edit />}
        >
          Edit Your Campaign
        </Button>

        <Typography
          variant="h4"
          component="div"
          style={{ textAlign: "left", fontWeight: 800, marginTop: 20 }}
        >
          Details
        </Typography>
        {FIELD_TO_DISPLAY.map((field) => {
          return (
            <Paper
              elevation={2}
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                marginBottom: 20,
                borderRadius: 10,
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <div style={{ margin: 10 }}>
                <Typography
                  variant="h5"
                  component="div"
                  style={{ textAlign: "left" }}
                >
                  {field.title}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{ textAlign: "left", whiteSpace: "pre-wrap" }}
                >
                  {campaign[field.key] !== undefined
                    ? campaign[field.key].replaceAll("\\n", "\n")
                    : null}
                </Typography>
              </div>
            </Paper>
          );
        })}

        <Typography
          variant="h5"
          component="div"
          style={{ textAlign: "left", marginTop: 40, fontWeight: 800 }}
        >
          Tips to Get Your Campaign Started
        </Typography>

        <Button
          style={{
            textAlign: "center",

            marginRight: "0",
            display: "inline-block",
            float: "right",
            marginLeft: 10,
          }}
          onClick={() => setGettingStartedOpen(true)}
        >
          Getting Started
        </Button>

        <Typography
          variant="body"
          component="div"
          style={{ textAlign: "left", marginTop: 20 }}
        >
          You're a rockstar! Congrats on launching your campaign. Here are some
          tips to get your started:
        </Typography>
        <Typography
          variant="h5"
          component="div"
          style={{ textAlign: "left", marginTop: 40, fontWeight: 800 }}
        >
          Want to invite specific creators to join this campaign?
        </Typography>
        <Button
          style={{
            backgroundColor: "#4158D0",
            backgroundImage:
                "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            textAlign: "center",

            marginRight: "0",
            display: "inline-block",
            float: "right",
            marginLeft: 10,
          }}
          onClick={copyInviteLink}
        >
          Copy Invite Link
        </Button>
        <Typography
          variant="body"
          component="div"
          style={{ textAlign: "left", marginTop: 20 }}
        >
          Invite any creator to join your campaign by sending them your campaign invite link.
        </Typography>
        <img
                  src={multipleCreators}
                  style={{
                    borderRadius: 20,
                    marginTop: 10,
                    width: "30%",
                    height: "12%"
                    
                  }}
                ></img>
        <Grid
          container
          sx={{
            margin: "0 auto",
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: "black",
            minHeight: 10,
            marginTop: 50,
            marginBottom: 70,
          }}
        ></Grid>
        <Typography
          variant="h5"
          component="div"
          style={{ textAlign: "left", marginTop: 40, fontWeight: 800 }}
        >
          Skip the Paperwork, Powered by AI
        </Typography>
        <Button
          style={{
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            textAlign: "center",

            marginRight: "0",
            display: "inline-block",
            float: "right",
            marginLeft: 10,
          }}
          onClick={generateBrief}
        >
          Generate Creator Brief
        </Button>
        <Typography
          variant="body"
          component="div"
          style={{ textAlign: "left", marginTop: 20 }}
        >
          Using Generative AI, create your customized Creator Marketing Brief to
          share with creators
        </Typography>
        {recommendations.length > 0 && (
          <>
            <Grid
              container
              sx={{
                margin: "0 auto",
                textAlign: "center",
              }}
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: "black",
                minHeight: 10,
                marginTop: 70,
                marginBottom: 70,
              }}
            ></Grid>
            <Typography
              variant="h4"
              component="div"
              style={{ textAlign: "left", fontWeight: 800 }}
            >
              Gigl Recommendations
            </Typography>
            <Typography
              variant="h6"
              component="div"
              style={{ textAlign: "left", marginBottom: 20 }}
            >
              We think these creators may be a great fit for your campaign!
            </Typography>
            <Grid container spacing={2}>
              {recommendations.map((creator, index) => {
                return (
                  <Grid item xs={12} sm={10} md={3} key={index} style={{ paddingTop: "35px" }}>
                    <RecommendationCard data={creator} />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
        


        
        {matches.length > 0 && (

          <div>
          <Grid
          container
          sx={{
            margin: "0 auto",
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "black", minHeight: 10, marginTop: 70, marginBottom: 70 }}
          >
          </Grid>
              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: "left", fontWeight: 800, marginBottom: 20 }}
              >
                Current Partners
              </Typography>

            <div >

              <Grid container spacing={2}>
              {matches.map((creator) => {
                return (
                <Grid item xs={12}>
                  <Card component="div" style={{backgroundColor: "#ffedf7", borderRadius: 6}}>

                <CardContent> 
                  <Avatar
                    src={creator.images && creator.images[0]}
                    sx={{ width: 60, height: 60, marginRight: 2, marginBottom: 2, float: "left" }}
                  />
                  
                <Typography gutterbottom variant="h6" component="div" sx={{marginTop: 1}} style={{fontWeight: 600, float: "left"}}>
                  {creator.name}
                </Typography>

                <Button
                alignItems="center"
                justifyContent="center"
                style={{
                  marginRight: "0",
                  float: "right",
                  marginLeft: 10,

                }}
                onClick={() => navigate(`/campaigns/${campaignId}/workstream`)}
              >
                Visit Creator Workstream
              </Button>
                      

                </CardContent>
                </Card>
                 </Grid>
                );
              })}
              </Grid>
            </div>
          </div>
          )}





        {bestMatchCreators.length > 0 && (
          <div>
          <Grid
          container
          sx={{
            margin: "0 auto",
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "black", minHeight: 10, marginTop: 70, marginBottom: 70 }}
        >
        </Grid>
              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: "left", fontWeight: 800, }}
              >
                Top Featured Creator Applications
              </Typography>
              <Typography
                variant="h6"
                component="div"
                style={{ textAlign: "left", marginBottom: 20,  }}
              >
                Powered by AI
              </Typography>
          <Card
            sx={{
              backgroundColor: "#ffedf7",
              width: "100vw",
              marginLeft: "-10vw",
            }}
            style={{ padding: 20 }}
          >

            <div style={{ marginLeft: "8vw" }}>

              <Grid container spacing={2}>
              {bestMatchCreators.map((creator) => {
                return (
                <Grid item xs={4}>
                  <CreatorCard
                    key={`best-match-${creator.userDocId}`}
                    creator={creator}
                    handleClick={handleCardClick}
                  />
                  </Grid>
                );
              })}
              </Grid>
            </div>
          </Card>
          </div>
        )}

        <Grid
          container
          sx={{
            margin: "0 auto",
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: "black",
            minHeight: 10,
            marginTop: 70,
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          }}
        ></Grid>

        <Typography
          variant="h4"
          component="div"
          style={{ textAlign: "left", marginTop: 60, fontWeight: 800 }}
        >
          Creators That Love Your Brand
        </Typography>

        {hasMatch && (
          <Button
            style={{
              marginRight: "0",
              display: "inline-block",
              float: "right",
              marginLeft: 10,
            }}
            onClick={() => navigate(`/campaigns/${campaignId}/workstream`)}
          >
            Visit Creator Workstream
          </Button>
        )}

        <Typography
          variant="body"
          component="div"
          style={{ textAlign: "left", marginTop: 10 }}
        >
          Below are creators that have applied to work with you on this
          campaign.
          <br />
          Set your campaign public in order to receive applications.
        </Typography>

        <Typography
          variant="h5"
          component="div"
          style={{ textAlign: "left", marginBottom: 20, marginTop: 20 }}
        >
          Filters{" "}
          {/* <Button sx={{ height: 30, marginRight: 1 }}>Location </Button> */}
        </Typography>
        <FormControl sx={{ height: 25, marginRight: 1, minWidth: 200 }}>
          <InputLabel id="level-select-label">Creator Level</InputLabel>
          <Select
            labelId="level-select-label"
            id="level-select"
            value={creatorLevel}
            label="Creator Level"
            variant="outlined"
            sx={{ borderRadius: 200 }}
            autoWidth
            onChange={(e) => setCreatorLevel(e.target.value)}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"micro"}>Micro</MenuItem>
            <MenuItem value={"major"}>Major</MenuItem>
            <MenuItem value={"celebrity"}>Celebrity</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ height: 25, marginRight: 1, minWidth: 200 }}>
          <InputLabel id="level-select-label">Industry</InputLabel>
          <Select
            labelId="industry-select-label"
            id="industry-select"
            value={creatorIndustry}
            label="Industry"
            variant="outlined"
            sx={{ borderRadius: 200 }}
            autoWidth
            onChange={(e) => setCreatorIndustry(e.target.value)}
          >
            <MenuItem value={"any"} key={"any"}>
              Any
            </MenuItem>
            {INDUSTRIES.map((industry) => (
              <MenuItem value={industry} key={industry}>
                {industry}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        <br />
        {creators.length === 0 && <>Waiting for responses!</>}
        <Grid container spacing={2}>
          {displayedCreators
            .slice(6 * creatorPageIndex - 6, 6 * creatorPageIndex)
            .map((creator) => {
              return (
                <Grid
                  item
                  xs={4}
                  key={`creator-${creator.userDocId}-${creator.id}`}
                >
                  <CreatorCard
                    creator={creator}
                    handleClick={handleCardClick}
                  />
                </Grid>
              );
            })}
        </Grid>
        <Pagination
          count={Math.ceil(displayedCreators.length / 6)}
          onChange={(e, value) => setCreatePageIndex(value)}
          sx={{ marginTop: 2, float: "right" }}
        />

        <Grid
          container
          sx={{
            margin: "0 auto",
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "black", minHeight: 10, marginTop: 100 }}
        ></Grid>

        <Typography
          variant="h4"
          component="div"
          style={{
            textAlign: "left",
            fontWeight: 800,
            marginTop: 60,
            marginBottom: 20,
          }}
        >
          Easy Discovery: Identify New Creators & Outreach with Ease
        </Typography>
        <Typography
          variant="body"
          component="div"
          style={{ textAlign: "left", marginTop: 10, marginBottom: 20 }}
        >
          Dont see a perfect creator match above? Easily discover new creators
          below and directly outreach in one click!
        </Typography>

        <img
          alt=""
          fetchpriority="high"
          src={MoreCreators}
          style={{
            maxWidth: "95%",
          }}
        ></img>

        <Typography
          variant="h5"
          component="div"
          style={{ textAlign: "left", marginBottom: 20, marginTop: 40 }}
        >
          Filter To Find Your Perfect Match{" "}
          {/* <Button sx={{ height: 30, marginRight: 1 }}>Location </Button> */}
        </Typography>

        <OutreachTable campaignId={campaignId} />

        <Grid
          container
          sx={{
            margin: "0 auto",
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "black", minHeight: 10, marginTop: 280 }}
        >
        </Grid>



        {!analytics ||
          (analytics.length === 0 && (
            <>
              <Typography
                variant="h4"
                component="div"
                style={{
                  textAlign: "center",
                  marginTop: 60,
                  marginBottom: 40,
                  fontWeight: 600,
                }}
              >
                Analytics, Powered By AI
              </Typography>
              <Typography
                variant="body1"
                component="div"
                style={{ textAlign: "center", marginTop: 5, marginBottom: 40 }}
              >
                Track campaign wide analytics below.
                <br></br>
                For creator specific views, engagement metrics, and conversions
                <br></br>
                visit the creator workstream page, available once you have
                partnered with a creator.
              </Typography>

              <Card
                sx={{
                  width: "60vw",
                  minWidth: 500,
                  margin: "0 auto",
                  boxShadow: 2,
                  minHeight: 400,
                }}
              >
                <CardContent>
                  <Typography variant="h4">Views</Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      textAlign: "left",
                      marginTop: 10,
                    }}
                  >
                    Track campaign wide views here once your campaign is live
                  </Typography>
                  <Box width={"90%"} margin={"0 auto"}></Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  width: "60vw",
                  minWidth: 500,
                  margin: "0 auto",
                  boxShadow: 2,
                  minHeight: 400,
                  marginTop: 10,
                }}
              >
                <CardContent>
                  <Typography variant="h4">Engagement</Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      textAlign: "left",
                      marginTop: 10,
                    }}
                  >
                    Track campaign wide engagement here once your campaign is
                    live
                  </Typography>
                  <Box width={"90%"} margin={"0 auto"}></Box>
                </CardContent>
              </Card>
            </>
          ))}

        {analytics && analytics.length > 0 && (
          <>
            <Typography
              variant="h4"
              component="div"
              style={{
                textAlign: "center",
                marginTop: 60,
                marginBottom: 40,
                fontWeight: 600,
              }}
            >
              Analytics, Powered By AI
            </Typography>
            <Grid
              container
              style={{ marginBottom: 50 }}
              gap={5}
              alignContent={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={5}>
                <Card>
                  <div>
                    <Typography
                      variant="h5"
                      component="div"
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        marginLeft: 10,
                        fontWeight: 200,
                      }}
                    >
                      Overall Views
                    </Typography>

                    <Typography
                      gutterBottom
                      variant="body2"
                      component="div"
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        marginLeft: 10,
                      }}
                    >
                      Your campaign has reached {totalViews} views!
                    </Typography>
                    <Line
                      data={reachData}
                      options={{
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>
                </Card>
              </Grid>
              <Grid item xs={5}>
                <Card>
                  <Typography
                    variant="h5"
                    component="div"
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 10,
                      fontWeight: 200,
                    }}
                  >
                    Overall Engagement
                  </Typography>

                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}
                  >
                    Your campaign has reached {totalEngagement} engagements!
                  </Typography>
                  <Line
                    data={engagementData}
                    options={{
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          grid: {
                            display: false,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
}
