import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Paper,
  CardHeader,
  Avatar,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Alert,
  Box,
} from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {Button,LeftSideBar} from "../components";
import { Line, Bar } from "react-chartjs-2";
import instagram from "../assets/ig.png";
import tiktok from "../assets/tiktok.png";
import yt from "../assets/yt.png";
import twitter from "../assets/twitter.png";
import "chart.js/auto"; // ADD THIS
import { fetchGet, fetchPost, formatStat } from "../util/helpers";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import CreatorCard from "../Campaign/CreatorCard";
import { INDUSTRIES } from "../util/constants";
import CampaignDialog from "../Campaign/CampaignDialog";
import { Edit } from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import GroupIcon from "@mui/icons-material/Group";
import DiscoveryTable from "./DiscoveryTable";
import LinkedInDiscoveryTable from "./LinkedInDiscoveryTable";
import Sebb from "../assets/sebb_creator.png";
import Thais from "../assets/thais_creator.png";
import Disha from "../assets/disha_creator.png";
import Sahil from "../assets/sahil_creator.png";
import ShrutiCard from "../assets/Shruti Card.png";
import DishaCard from "../assets/Disha card.png";
import AlexCard from "../assets/ALex Card.png";
import SebbCard from "../assets/Sebb Card.png";
import TosinCard from "../assets/Tosin Card.png";
import TasminCard from "../assets/Tasmin Card.png";
import MoreCreators from "../assets/moreCreators.PNG";
import TrendingCreators from "../assets/TrendingCreators.PNG";
import TrendingHashtags from "../assets/TrendingHashtags.PNG";
import ContentFormats from "../assets/ContentFormats.PNG";
import featuredCreators1 from "../assets/featuredCreators_1.PNG";
import featuredCreators2 from "../assets/featuredCreators_2.PNG";


function getLastWeek(week = 1) {
  var today = new Date();
  var lastWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7 * week,
  );
  return lastWeek;
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function CreatorDiscovery() {
  const [rightGridHeight, setRightGridHeight] = useState(0);
  const rightGridRef = useRef(null);
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState({});
  const [briefModalOpen, setBriefModalOpen] = useState(false);
  const [briefURL, setBriefURL] = useState("");
  const [creatorDialogOpen, setCreatorDialogOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState({});
  const [creators, setCreators] = useState([]);
  const [bestMatchCreators, setBestMatchCreators] = useState([]);
  const [hasMatch, setHasMatch] = useState(false);
  const [creatorLevel, setCreatorLevel] = useState("any");
  const [creatorLevelOutreach, setCreatorLevelOutreach] = useState("any");
  const [creatorIndustry, setCreatorIndustry] = useState();
  const { campaignId } = useParams();
  const [activeDialogOpen, setActiveDialogOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { setIsLoading, setLoadingText } = useContext(LoadingContext);
  const [analytics, setAnalytics] = useState([]);
  const [creatorPageIndex, setCreatePageIndex] = useState(1);

  useEffect(() => {
    if (rightGridRef.current) {
      const updateHeight = () => {
        setRightGridHeight(rightGridRef.current.clientHeight);
      };

      updateHeight();

      window.addEventListener('resize', updateHeight);

      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }
  }, [rightGridRef.current]);

  return (
    <>
      <Grid container>
        <div style={{ width: "90vw" }}>
          <Grid container>
            <Grid item xs={2} style={{ textAlign: "left", height: rightGridHeight }}>
              <LeftSideBar />
            </Grid>
            <Grid item xs={10} ref={rightGridRef}>
              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: "left", fontWeight: 800, marginBottom: 20 }}
              >
                Easy Discovery: Identify New Creators For Your Next Campaign
              </Typography>

              <Typography
                variant="body"
                component="div"
                style={{ textAlign: "left", marginTop: 10, marginBottom: 20 }}
              >
                With Gigl's Easy Discovery, you can indentify new creators to
                partner with for your next influencer marketing campaign. Use
                insights to discover the right creator for you. In order to
                partner with a creator, visit your dashboard, launch a campaign
                and directly outreach to that creator.
              </Typography>

              <img
                alt=""
                fetchpriority="high"
                src={MoreCreators}
                style={{
                  maxWidth: "95%",
                }}
              ></img>

              <Typography
                variant="h5"
                component="div"
                style={{ textAlign: "left", marginBottom: 20, marginTop: 40 }}
              >
                Filter To Find Your Perfect Match{" "}
                {/* <Button sx={{ height: 30, marginRight: 1 }}>Location </Button> */}
              </Typography>

              <br />

          <DiscoveryTable campaignId={campaignId} creatorLevel={creatorLevelOutreach} />
          {analytics && analytics.length > 0 && (
            <> </>
          )}
          
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>                   
                     <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
          <Grid
          container

          alignItems="center"
          justifyContent="center"
          style={{ 
              minHeight: 10,
              backgroundColor: "black",
              marginBottom: 50
           }}
        >

        </Grid>

              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: "left", fontWeight: 800, marginBottom: 20 }}
              >
                B2B Trailblazers & Trending Voices
              </Typography>
              <Typography
                variant="body"
                component="div"
                style={{ textAlign: "left", marginTop: 10, marginBottom: 20 }}
              >
                Launching a B2B campaign? Use Gigl insights to find the right
                creator for you. Check out some of the trending top voices on
                LinkedIn. Filter by name, level, industry expertise, and audience region.
              </Typography>

              <br />

              <LinkedInDiscoveryTable
                campaignId={campaignId}
                creatorLevel={creatorLevelOutreach}
              />
              {analytics && analytics.length > 0 && <> </>}

              <br />
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{
                  minHeight: 10,
                  backgroundColor: "black",
                  marginBottom: 50,
                }}
              ></Grid>

              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: "left", fontWeight: 800, marginBottom: 20 }}
              >
                Top Creators Across Industries
              </Typography>
              <Typography
                variant="body"
                component="div"
                style={{ textAlign: "left", marginTop: 10, marginBottom: 10 }}
              >
                Check out some of the biggest trending influencers across major industries.
              </Typography>
              
              <img
                alt=""
                fetchpriority="high"
                src={TrendingCreators}
                style={{
                  maxWidth: "85%",
                  maxHeight: "80%",

                }}
              ></img>

              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: "left", fontWeight: 800, marginBottom: 20 }}
              >
                Most Popular & Trending Hashtags
              </Typography>
              <Typography
                variant="body"
                component="div"
                style={{ textAlign: "left", marginTop: 10, marginBottom: 10 }}
              >
                Check out some of the most popular and trending hashtags across major social channels.
              </Typography>
              
              <img
                alt=""
                fetchpriority="high"
                src={TrendingHashtags}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",

                }}
              ></img>


            <Typography
                variant="h4"
                component="div"
                style={{ textAlign: "left", fontWeight: 800, marginBottom: 20 }}
              >
                Popular Video Content Formats on Social
              </Typography>
              <Typography
                variant="body"
                component="div"
                style={{ textAlign: "left", marginTop: 10, marginBottom: 10 }}
              >
                Check out some of the most viral and trending video styles, and get inspiration for your next post.
              </Typography>
              
              <img
                alt=""
                fetchpriority="high"
                src={ContentFormats}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",

                }}
              ></img>


              <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{
                  minHeight: 10,
                  backgroundColor: "black",
                  marginBottom: 50,
                }}
              ></Grid>

              <Typography
                variant="h5"
                component="div"
                style={{ textAlign: "left", marginBottom: 20, marginTop: 50 }}
              >
                Trending Creators On Gigl This Month
              </Typography>

              <Typography
                variant="body"
                component="div"
                style={{ textAlign: "left", marginTop: 10, marginBottom: 20 }}
              >
                Check out some of the most popular, and highest performing
                creators this month.
                <br />
                To partner with a creator, visit your Dashboard and launch a
                campaign to start collaborating.
              </Typography>


              <img
                alt=""
                fetchpriority="high"
                src={featuredCreators1}
                style={{
                  maxWidth: "95%",
                  marginBottom: 20,
                }}
              ></img>


              <img
                alt=""
                fetchpriority="high"
                src={featuredCreators2}
                style={{
                  maxWidth: "95%",
                }}
              ></img>

              <Typography
                variant="h5"
                component="div"
                style={{ textAlign: "left", marginBottom: 20, marginTop: 50 }}
              >
                Partner With More Amazing Creators
              </Typography>

              <Typography
                variant="body"
                component="div"
                style={{ textAlign: "left", marginTop: 10, marginBottom: 20 }}
              >
                Visit your Dashboard and launch a campaign to start
                collaborating.
              </Typography>

              <Button
                basic
                style={{
                  marginTop: 20,
                  borderRadius: 3,
                  width: "40%",
                  marginBottom: 30,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  fontSize: 12,
                }}
                onClick={() => navigate("/campaigns")}
              >
                Vist My Dashboard
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}
