import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { fetchGet, fetchPost } from "../util/helpers";
import toast, { Toaster } from "react-hot-toast";
import Button from "../components/Button";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const followerComparator = (a, b) => {
  return followerCountToNumber(a) - followerCountToNumber(b);
};
const followerCountToNumber = (followers) => {
  let amountMultiplier = followers.slice(-1).toLowerCase();
  if (amountMultiplier === "k") {
    amountMultiplier = 1000;
  } else if (amountMultiplier === "m") {
    amountMultiplier = 1000000;
  } else {
    amountMultiplier = 1;
  }
  const amount = parseFloat(followers.slice(0, -1));
  return amount * amountMultiplier;
};
const STATUS_TO_LABEL = {
  "not-contacted": "Not contacted",
  delivered: "Delivered",
  opened: "Opened",
  clicked: "Clicked",
  failed: "Failed",
};

const STATUS_TO_COLOR = {
  "not-contacted": "#ebebeb",
  delivered: "rgb(60 217 139)",
  opened: "#6bc1ff",
  clicked: "#b399ff",
  failed: "red",
};
export default function LinkedInDiscoveryTable(props) {
  const [data, setData] = useState([]);
  const { campaignId } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [creatorEmail, setCreatorEmail] = useState("");
  const [creatorLevelOutreach, setCreatorLevelOutreach] = useState("any");
  const [locationSearch, setLocationSearch] = useState("any");
  const [contactStatus, setContactStatus] = useState("any");
  const [nameSearch, setNameSearch] = useState("");
  const [creatorLocation, setCreatorLocation] = useState("");

  const fetchOutreach = () => {
    fetchGet(`/brand/outreach/b2b?campaignId=${campaignId}`)
      .then((result) => result.result)
      .then((result) => {
        setData(result.map((res) => ({ ...res, id: res.linkedinAccount })));
      });
  };

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", flex: 1.5 },
      {
        field: "followers",
        headerName: "Followers",
        width: 160,
        flex: 1,
        valueGetter: (params) => params.value.toUpperCase(),
        sortComparator: followerComparator,
      },
      { field: "industry", headerName: "Industry", width: 130, flex: 2 },
      {
        field: "location",
        headerName: "Region",
        width: 50,
        flex: 1,
        valueGetter: (params) => {
          return params.value ? params.value.join(", ") : "";
        },
      },
      {
        field: "connect",
        type: "actions",
        headerName: "Connect",
        width: 180,
        sortable: false,
        getActions: (params) => [
          <Button
            style={{
              borderRadius: 20,
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
              fontSize: 16,
            }}
            rel="noopener noreferrer"
            target="_blank"
            href={`${params.row.linkedinAccount}`}
          >
            Connect
          </Button>,
        ],
      },
    ],
    [data],
  );

  useEffect(() => {
    fetchOutreach();
  }, []);

  const rows = useMemo(() => {
    const checkName = (row) => {
      if (nameSearch) {
        if (
          row.name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          row.handle.toLowerCase().includes(nameSearch.toLowerCase())
        ) {
          return true;
        }
        return false;
      }
      return true;
    };
    const checkLevel = (row) => {
      const count = followerCountToNumber(row.followers);
      if (creatorLevelOutreach) {
        if (creatorLevelOutreach === "micro" && count <= 50000) {
          return true;
        } else if (
          creatorLevelOutreach === "major" &&
          count > 50000 &&
          count < 150000
        ) {
          return true;
        } else if (creatorLevelOutreach === "celebrity" && count > 100000) {
          return true;
        } else if (creatorLevelOutreach === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    const checkStatus = (row) => {
      if (contactStatus) {
      }
      return true;
    };
    return data.filter((rowData) => {
      return checkStatus(rowData) && checkName(rowData) && checkLevel(rowData);
    });
  }, [creatorLevelOutreach, data, nameSearch, contactStatus]);

  const closeDialog = () => {
    setConfirmOpen(false);
    setCreatorEmail("");
    setCreatorName("");
  };
  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
      />
    </div>
  );
}
