import { memo, useState } from "react";
import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Avatar,
    Grid,
    Button
} from "@mui/material";
import launch from "../assets/launch.jpg";
import LearnMoreDialog from "./LearnMoreDialog";



const RecommendationCard = ({ data }) => {
    const [openDialog, setOpenDialog] = useState(false);
    return <>
        <div key={data.email}
            style={{ position: "relative" }}>
            <Card>
                <CardHeader
                    avatar={<Avatar fetchPriority="high" sx={{ width: 60, height: 60, marginRight: 0 }} alt="avtar" src={data.name && !data.profileImage ? launch : data.profileImage} />}
                    title={
                        <Typography component="div" variant="h6" sx={{ marginLeft: 2, fontWeight: 800, textAlign: "left", lineHeight:"1.2" }}>
                            {data.name}
                        </Typography>
                    }
                    sx={{ textAlign: 'center', paddingBottom: 0 }}
                >
                </CardHeader>
                <CardContent sx={{ textAlign: 'center', padding: "0px 1.75em" }}>
                    <Typography sx={{ fontSize: ".8em",  minHeight: "35px", lineHeight: "1.2" }}>
                        {data.industry}
                    </Typography>
                    <Grid component="div" sx={{ margin: 1.3 }}>
                        <Typography component="div" sx={{ fontWeight: "bold" }}>
                            {data.followers && data.followers.toUpperCase()}
                        </Typography>
                        <Typography sx={{ fontSize: ".8em", fontWeight: "bold" }}>
                            Followers
                        </Typography>
                    </Grid>
                    <Button
                        style={{
                            backgroundColor: "#4158D0",
                            backgroundImage:
                                "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                            position: "absolute",
                            zIndex: 100,
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: -15,
                            left: "50%",
                            transform: "translate(-50%, 0)",
                            color: "white"
                        }}
                        onClick={() => {
                            setOpenDialog(true);
                        }}
                    > View More</Button>
                </CardContent>
            </Card>
            <LearnMoreDialog openDialog={openDialog} setOpenDialog={() => setOpenDialog(false)} data={data} />

        </div>
    </>

}

export default memo(RecommendationCard);