import {
  Typography,
  Avatar,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from "@mui/material";
import launch from "../assets/creatorOutreachAvatar.PNG";
import creatorRates from "../assets/creatorRates.PNG"
import primaryChannels from "../assets/primaryChannels.PNG"
import { useNavigate } from "react-router-dom";



import { parseFollowers } from "../util/helpers";

const LearnMoreDialog = ({ openDialog, setOpenDialog, data }) => {

  const followers = parseFollowers(data.followers);
  const navigate = useNavigate();
  return <>
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog}
      PaperProps={{
        style: { borderRadius: 20, minWidth: "80vw", maxHeight:"80vh" },
      }}
    >
      <DialogTitle>
        <Avatar
          fetchPriority="high"
          src={data.profileImage || launch}
          sx={{ width: 100, height: 100, marginRight: 5, float: "right" }}
        />

        <h3 style={{ marginTop: 25, float: "left" }}>
          Learn More About {data.name}
        </h3>
      </DialogTitle>
      <DialogContent>
        <Grid
          style={{
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
            minHeight: 5,
            width: 890,
            marginBottom: 30,
          }}
        ></Grid>

        <Grid style={{ width: "50%", float: "right" }}>
          <Grid container>
            <Grid item xs={3}>
              <Typography gutterBottom variant="h5" component="div">
                {data.followers}
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                Followers
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography gutterBottom variant="h5" component="div">
                {data.relevance}
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                Relevance
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 4 }}>
            Want to Send a Custom Email?
          </Typography>

          <Typography variant="body2" sx={{ marginBottom: 2, maxWidth: 400 }}>
            Click here to send your customized email. You can invite this
            creator to apply for your campaign so that you can continue your
            journey through the platform. If you would like to use a gigl
            templated email, you can close this field and select Gigl outreach
            in your dscovery table.
          </Typography>
          <Grid
            style={{
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
              minHeight: 5,
              width: 420,
            }}
          ></Grid>
          <br />
          <br />
          <Button
            style={{
              borderRadius: 20,
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
              fontSize: 16,
              width: 420,
              marginTop: 5,
              color:"white"
            }}
            href={`mailto:${data.email}?subject=Gigl: New Campaign Partnership Opportunity`}
          >
            Send a Custom Email
          </Button>

          <br />
          <br />

          <Button
          alignItems="right"
          style={{
            borderRadius: 20,
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: 16,
            width: 420,
            color:"white"
          }}
          rel="noopener noreferrer"
          target="_blank"
          href={data.link}
        >
          Visit Channel
        </Button>

        <br />
          <br />
        <Button
              alignItems="right"
              style={{
                borderRadius: 20,
                backgroundColor: "#4158D0",
                backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                fontSize: 16,
                width: 420,
                color:"white"
              }}
              onClick={() => { navigate(`/outreach/${data.email}`) }}
            >
              View Full Creator Profile
            </Button>
        <br/>
        <br/>


                <img
                    src={primaryChannels}
                    float="left"
                    style={{
                      height: 50,
                      width: 200,
                      marginLeft: 100,
                      borderRadius: 5,
                      objectFit: "cover",
              
                    }}
                  />

        </Grid>

        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          About:
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          style={{ float: "left", maxWidth: 400, marginTop: 4 }}
        >
          This creator has built a great community with over{" "}
          {data.followers} followers. People follow this creator for{" "}
          {data.industry} and more topics. They are currently located in{" "}
          {data.location}, and are relevent for {data.relevance}{" "}
          campaigns.
        </Typography>

        <br />

        <br />
        <br />
        <br />
        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          Location:
        </Typography>
        <Typography gutterBottom variant="body2">
          {data.location}{data.city && `, ${data.city}`}
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          Audience Focus:
        </Typography>
        <Typography gutterBottom variant="body2">
          {data.industry}
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          Predicted Post Views, Powered by AI
        </Typography>
        <Typography gutterBottom variant="body2">
          {data.reach}
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          style={{ fontWeight: 600, marginTop: 20 }}
        >
          Paid Partnership Rate Range:
        </Typography>

        {followers < 10000 && (
          <Typography gutterBottom variant="body2">
            $100-$500 per post
          </Typography>
        )}
        {followers > 10000 &&
          followers < 50000 && (
            <Typography gutterBottom variant="body2">
              $500-$2000 per post
            </Typography>
          )}
        {followers >= 50000 &&
          followers < 100000 && (
            <Typography gutterBottom variant="body2">
              $2000-$2800 per post
            </Typography>
          )}
        {followers >= 100000 &&
          followers < 500000 && (
            <Typography gutterBottom variant="body2">
              $3000-$5000 per post
            </Typography>
          )}
        {followers >= 500000 &&
          followers < 1000000 && (
            <Typography gutterBottom variant="body2">
              $5000-$7000 per post
            </Typography>
          )}
        {followers >= 1000000 &&
          followers < 1500000 && (
            <Typography gutterBottom variant="body2">
              $6000-$8000 per post
            </Typography>
          )}
        {followers >= 1500000 && (
          <Typography gutterBottom variant="body2">
            $8000 - $10,000+ per post
          </Typography>
        )}

        <Typography  gutterBottom variant="body2" style={{ marginTop: 20}}>
              Get the latest creator compensation data:
            </Typography>

            <img
                    src={creatorRates}
                    // height={"220px"}
                    // width={"280px"}
                    float="left"
                    style={{
                      height: 250,
                      width: 400,
                      borderRadius: 5,
                      objectFit: "cover",
              
                    }}
                  />

          <br />

        <br />


      </DialogContent>
      <DialogActions>
        <Button onClick={setOpenDialog} style={{ backgroundColor: "black", fontSize: "1.1em", color: "white", borderRadius: "25px", padding: "10px 15px" }}>Close</Button>
      </DialogActions>
    </Dialog>
  </>

}

export default LearnMoreDialog;