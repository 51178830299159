import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  Avatar,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Slider,
  Input,
  InputAdornment,
  Switch,
  FormControlLabel,
  Grow,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import {
  fetchGet,
  fetchPost,
  fetchPut,
  generateMockAnalytics,
  handleUpload,
  idGenerator,
  transformFirebaseTime,
} from "../util/helpers";
import LineChart from "../components/LineChart";
import FileUpload from "../components/FileUpload";
import host from "../assets/host.jpg";
import dayjs, { Dayjs } from "dayjs";
import { INDUSTRIES } from "../util/constants";

const COUNTRIES = [
  "All",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "South Korea",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia (formerly Macedonia)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export default function CampaignDialog(props) {
  const { open, onClose, onSubmit } = props;
  const { setIsLoading, setLoadingText } = useContext(LoadingContext);
  const [isNegotiable, setIsNegotiable] = useState(
    props.campaign.budget ? false : true,
  );

  const [newCampaignData, setNewCampaignData] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!props.campaign.id) {
      alert("ERROR! No campaign found");
    }
    await fetchPut(
      `/brand/campaigns/${props.campaign.id}`,
      {
        ...newCampaignData,
      },
      setIsLoading,
    );

    onClose();
    onSubmit();
  };

  useEffect(() => {
    setNewCampaignData(props.campaign);
  }, [props.campaign]);
  const handleUpdateNewCampaign = (e) => {
    setNewCampaignData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const getGeneratedText = () => {
    if (
      !newCampaignData.description ||
      newCampaignData.description.trim() === ""
    ) {
      alert("Please fill out your brand name and description first!");
      return;
    }
    setLoadingText("Generating talking points");
    fetchPost(
      "/brand/talking_points/generate",
      {
        brandName: newCampaignData.brand,
        campaignDetails: newCampaignData.description,
      },
      setIsLoading,
    ).then((res) => {
      console.log("res", res);
      setNewCampaignData((prev) => ({
        ...prev,
        talking_points: res.message.trim(),
      }));
    });
  };
  console.log("newCampaignData", newCampaignData);
  return (
    <>
      <Dialog open={open} fullWidth onClose={onClose}>
        <DialogTitle>
          {props.campaign ? "Edit" : "Create a"} Campaign
        </DialogTitle>
        <DialogContent>
          <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Campaign Name
            </Typography>
            <TextField
              fullWidth
              required
              name="name"
              defaultValue={newCampaignData.name}
              onChange={handleUpdateNewCampaign}
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Brand
            </Typography>
            <TextField
              fullWidth
              name="brand"
              required
              defaultValue={newCampaignData.brand}
              onChange={handleUpdateNewCampaign}
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Industry Focus
            </Typography>
            <FormControl fullWidth required>
              <Select
                value={newCampaignData.industry || []}
                name="industry"
                required
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCampaignData((prev) => ({
                    ...prev,
                    [e.target.name]:
                      typeof value === "string" ? value.split(",") : value,
                  }));
                }}
                multiple
              >
                {INDUSTRIES.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Campaign Details
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={4}
              name="description"
              onChange={handleUpdateNewCampaign}
              defaultValue={newCampaignData.description}
              required
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Deliverables
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={4}
              name="deliverables"
              required
              onChange={handleUpdateNewCampaign}
              defaultValue={newCampaignData.deliverables}
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
              }}
            >
              Talking Points
            </Typography>
            <Button
              variant="caption"
              style={{
                textAlign: "left",
                marginTop: 10,
                marginBottom: 10,
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={getGeneratedText}
            >
              Get AI generated talking points
            </Button>
            <TextField
              fullWidth
              multiline
              minRows={4}
              name="talking_points"
              required
              onChange={handleUpdateNewCampaign}
              value={newCampaignData.talking_points}
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Platforms
            </Typography>

            <FormControl fullWidth required>
              <Select
                value={newCampaignData.platforms || []}
                name="platforms"
                required
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCampaignData((prev) => ({
                    ...prev,
                    [e.target.name]:
                      typeof value === "string" ? value.split(",") : value,
                  }));
                }}
                multiple
              >
                {[
                  { label: <>TikTok</>, value: "tiktok" },
                  { label: "YouTube", value: "youtube" },
                  { label: "Instagram", value: "instagram" },
                  { label: "Twitter", value: "twitter" },
                ].map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Level of Creator
            </Typography>

            <FormControl fullWidth required>
              <Select
                value={newCampaignData.celebrity || []}
                name="celebrity"
                required
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCampaignData((prev) => ({
                    ...prev,
                    [e.target.name]:
                      typeof value === "string" ? value.split(",") : value,
                  }));
                }}
                multiple
              >
                {[
                  { label: "Micro", value: "micro" },
                  { label: "Major", value: "major" },
                  { label: "Celebrity", value: "celebrity" },
                ].map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Region
            </Typography>
            <FormControl fullWidth required>
              <Select
                value={newCampaignData.region || []}
                name="region"
                required
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCampaignData((prev) => ({
                    ...prev,
                    [e.target.name]:
                      typeof value === "string" ? value.split(",") : value,
                  }));
                }}
                multiple
              >
                {COUNTRIES.map((country) => ({
                  label: country,
                  value: country,
                })).map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Is this a paid partnership campaign?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  value={newCampaignData.isPaid || false}
                  defaultChecked={newCampaignData.isPaid || false}
                  onChange={(e) => {
                    setNewCampaignData((prev) => ({
                      ...prev,
                      isPaid: e.target.checked,
                    }));
                  }}
                />
              }
              label={newCampaignData.isPaid ? "Yes" : "No"}
            />
            {newCampaignData.isPaid && (
              <>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: 800,
                    textAlign: "left",
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  Budget Per Influencer
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      value={isNegotiable}
                      defaultChecked={isNegotiable}
                      onChange={(e) => setIsNegotiable(e.target.checked)}
                    />
                  }
                  label={
                    isNegotiable
                      ? "Budget is negotiable"
                      : "Budget is not negotiable"
                  }
                />{" "}
              </>
            )}
            {!isNegotiable && (
              <Input
                id="standard-adornment-amount"
                fullWidth
                name="budget"
                required
                onChange={handleUpdateNewCampaign}
                type="number"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            )}
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Is this a gift/merchandise campaign?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  value={newCampaignData.isMerchandise || false}
                  defaultChecked={newCampaignData.isMerchandise || false}
                  onChange={(e) => {
                    setNewCampaignData((prev) => ({
                      ...prev,
                      isMerchandise: e.target.checked,
                    }));
                  }}
                />
              }
              label={newCampaignData.isMerchandise ? "Yes" : "No"}
            />
            {newCampaignData.isMerchandise && (
              <TextareaAutosize
                name="merchandise_details"
                required
                onChange={handleUpdateNewCampaign}
                fullWidth
                style={{ minWidth: "100%", maxWidth: "100%", padding: 10 }}
                minRows={5}
                value={newCampaignData.merchandise_details}
                placeholder="Share more details about the merchandise and/or its monetary value"
              />
            )}
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Is this campaign for a creator event?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  value={newCampaignData.isEvent || false}
                  defaultChecked={newCampaignData.isEvent || false}
                  onChange={(e) => {
                    setNewCampaignData((prev) => ({
                      ...prev,
                      isEvent: e.target.checked,
                    }));
                  }}
                />
              }
              label={newCampaignData.isEvent ? "Yes" : "No"}
            />
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Written Concept Due Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                format={"YYYY-MM-DD"}
                defaultValue={dayjs(newCampaignData.written_due)}
                onChange={(value) => {
                  handleUpdateNewCampaign({
                    target: {
                      name: "written_due",
                      value: value.format("YYYY-MM-DD"),
                    },
                  });
                }}
              />
            </LocalizationProvider>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Draft Due Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs(newCampaignData.draft_due)}
                onChange={(value) => {
                  handleUpdateNewCampaign({
                    target: {
                      name: "draft_due",
                      value: value.format("YYYY-MM-DD"),
                    },
                  });
                }}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Final Due Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} required>
              <DatePicker
                required
                sx={{ width: "100%" }}
                defaultValue={dayjs(newCampaignData.final_due)}
                onChange={(value) => {
                  handleUpdateNewCampaign({
                    target: {
                      name: "final_due",
                      value: value.format("YYYY-MM-DD"),
                    },
                  });
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                style={{ display: "flex", marginRight: 0, marginTop: 20 }}
              >
                Submit
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
