import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";

import { memo, useEffect, useMemo, useState } from "react";
import { fetchGet } from "../util/helpers";
import CommentIcon from "@mui/icons-material/Comment";
import { format } from "d3-format";
function Analytics({ campaignId, creatorId }) {
  const [analytics, setAnalytics] = useState([]);
  const [conversions, setConversions] = useState([]);

  const fetchAnalytics = async () => {
    const result = await fetchGet(
      `/brand/campaigns/${campaignId}/creator/${creatorId}/analytics`,
    );
    result.result.sort((a, b) => new Date(a.date) - new Date(b.date));
    setAnalytics(result.result);
  };
  const fetchConversions = async () => {
    const result = await fetchGet(
      `/brand/campaigns/${campaignId}/creator/${creatorId}/conversions`,
    );
    setConversions(result.result);
  };

  useEffect(() => {
    if (campaignId && creatorId) {
      fetchAnalytics();
      fetchConversions();
    }
  }, [campaignId, creatorId]);
  const allData = useMemo(() => {
    const total = {};
    const result = [];
    analytics.forEach((data) => {
      const formattedDate = new Date(data.date).toISOString().slice(0, 10);
      if (total[formattedDate] === undefined) {
        total[formattedDate] = {
          views: 0,
          shares: 0,
          likes: 0,
          comments: 0,
          engagement: 0,
        };
      }
      total[formattedDate].views += data.views;
      total[formattedDate].shares += data.shares;
      total[formattedDate].likes += data.likes;
      total[formattedDate].comments += data.comments;
      total[formattedDate].engagement += data.engagement;
    });
    for (let key in total) {
      result.push([total[key], key]);
    }
    result.sort((a, b) => a[0] - b[0]);
    return [
      result.map((r) => r[0]),
      result.map((r) => new Date(r[1]).toISOString().slice(0, 10)),
    ];
  }, [analytics]);
  //   const engagementData = useMemo(() => analytics.map(data => data.engagement), [analytics])
  const allYValues = allData[0];
  const allXValues = allData[1];
  console.log("viewData", allData);
  console.log("conversions", conversions);

  if (!analytics || analytics.length === 0) {
    return (
      <>
        <Grid
          container
          sx={{
            margin: "0 auto",
            textAlign: "center",
          }}
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "black", minHeight: 10, marginTop: 100 }}
        ></Grid>

        <Typography
          variant="h4"
          style={{
            fontWeight: 800,
            textAlign: "left",
            marginBottom: 10,
            marginLeft: "20vw",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          Track Your Campaign Performance
        </Typography>
        <Card
          sx={{
            width: "60vw",
            minWidth: 500,
            margin: "0 auto",
            boxShadow: 2,
            minHeight: 400,
          }}
        >
          <CardContent>
            <Typography variant="h4">Views</Typography>
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "left",
                marginTop: 10,
              }}
            >
              Track views here once your campaign is live
            </Typography>
            <Box width={"90%"} margin={"0 auto"}></Box>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: "60vw",
            minWidth: 500,
            margin: "0 auto",
            marginTop: 10,
            boxShadow: 2,
            minHeight: 400,
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              style={{
                marginBottom: 10,
              }}
            >
              Engagement
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "left",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              Track your engagement here once your campaign is live
            </Typography>
            <Grid
              container
              sx={{ border: "1px solid gray", textAlign: "center" }}
            >
              <Grid item xs={3} sx={{ height: 60, border: "1px solid black" }}>
                <Typography variant="h5">--</Typography>
                <Typography variant="subtitle2">Engagements</Typography>
              </Grid>
              <Grid item xs={3} sx={{ height: 60, border: "1px solid black" }}>
                <Typography variant="h5">--</Typography>
                <Typography variant="subtitle2">Likes</Typography>
              </Grid>
              <Grid item xs={3} sx={{ height: 60, border: "1px solid black" }}>
                <Typography variant="h5">--</Typography>
                <Typography variant="subtitle2">Comments</Typography>
              </Grid>
              <Grid item xs={3} sx={{ height: 60, border: "1px solid black" }}>
                <Typography variant="h5">--</Typography>
                <Typography variant="subtitle2">Shares</Typography>
              </Grid>
            </Grid>
            <Box width={"90%"} margin={"0 auto"} height={"300"}></Box>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: "60vw",
            minWidth: 500,
            margin: "0 auto",
            marginTop: 10,
            boxShadow: 2,
            minHeight: 400,
          }}
        >
          <CardContent>
            <Typography variant="h4">Conversions</Typography>
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "left",
                marginTop: 10,
              }}
            >
              Conversions will be tracked here if you have launched a campaign
              with a product tracking link generated on Gigl
            </Typography>
            <Box width={"90%"} margin={"0 auto"}>
              {conversions.length > 0 && (
                <BarChart
                  dataset={conversions}
                  xAxis={[{ scaleType: "band", dataKey: "productURL" }]}
                  series={[{ dataKey: "visits", label: "Conversions" }]}
                  height={300}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </>
    );
  }
  return (
    <>
      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "black", minHeight: 10, marginTop: 100 }}
      ></Grid>
      <Typography
        variant="h4"
        style={{
          fontWeight: 800,
          textAlign: "left",
          marginBottom: 10,
          marginLeft: "20vw",
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        Track Your Campaign Performance
      </Typography>
      <Card
        sx={{
          width: "60vw",
          minWidth: 500,
          margin: "0 auto",
          boxShadow: 2,
        }}
      >
        <CardContent>
          <Typography variant="h4">Views</Typography>
          <Typography variant="subtitle1">
            Your campaign has reached{" "}
            {format(",")(allYValues[allYValues.length - 1].views).toUpperCase()}{" "}
            views!
          </Typography>
          <Box width={"90%"} margin={"0 auto"}>
            {allData.length > 0 && (
              <LineChart
                xAxis={[{ data: allXValues, scaleType: "point" }]}
                series={[
                  {
                    data: allYValues.map((d) => d.views),
                  },
                ]}
                yAxis={[
                  { valueFormatter: (val) => format(".2s")(val).toUpperCase() },
                ]}
                height={300}
              />
            )}
          </Box>
        </CardContent>
      </Card>
      <Card
        sx={{
          width: "60vw",
          minWidth: 500,
          margin: "0 auto",
          marginTop: 10,
          boxShadow: 2,
        }}
      >
        <CardContent>
          <Typography
            variant="h4"
            style={{
              marginBottom: 20,
            }}
          >
            Engagement
          </Typography>
          <Grid
            container
            sx={{ border: "1px solid gray", textAlign: "center" }}
          >
            <Grid item xs={3} sx={{ height: 60, border: "1px solid black" }}>
              <Typography variant="h5">
                {format(",")(
                  allYValues[allYValues.length - 1].engagement,
                ).toUpperCase()}
              </Typography>
              <Typography variant="subtitle2">Engagements</Typography>
            </Grid>
            <Grid item xs={3} sx={{ height: 60, border: "1px solid black" }}>
              <Typography variant="h5">
                {format(",")(
                  allYValues[allYValues.length - 1].likes,
                ).toUpperCase()}
              </Typography>
              <Typography variant="subtitle2">Likes</Typography>
            </Grid>
            <Grid item xs={3} sx={{ height: 60, border: "1px solid black" }}>
              <Typography variant="h5">
                {format(",")(
                  allYValues[allYValues.length - 1].comments,
                ).toUpperCase()}
              </Typography>
              <Typography variant="subtitle2">Comments</Typography>
            </Grid>
            <Grid item xs={3} sx={{ height: 60, border: "1px solid black" }}>
              <Typography variant="h5">
                {format(",")(
                  allYValues[allYValues.length - 1].shares,
                ).toUpperCase()}
              </Typography>
              <Typography variant="subtitle2">Shares</Typography>
            </Grid>
          </Grid>

          <Box width={"90%"} margin={"0 auto"}>
            {allData.length > 0 && (
              <LineChart
                xAxis={[{ data: allXValues, scaleType: "point" }]}
                series={[
                  {
                    data: allYValues.map((d) => d.engagement),
                  },
                ]}
                yAxis={[
                  { valueFormatter: (val) => format(".2s")(val).toUpperCase() },
                ]}
                height={300}
              />
            )}
          </Box>
        </CardContent>
      </Card>
      <Grid container sx={{ height: 60, marginTop: 10, width: "60vw", minWidth: 500, marginLeft: 37,  }}>
             <Typography variant="h5" >Engagement Rate: 
             <span variant="body" style={{backgroundColor: "#e0ffff"}}> {format(",")(
                  allYValues[allYValues.length - 1].engagement / allYValues[allYValues.length - 1].views * 100 ,
                ).toUpperCase()}%   </span>
             
             </Typography>
            </Grid>

      <Card
        sx={{
          width: "60vw",
          minWidth: 500,
          margin: "0 auto",
          marginTop: 8,
          boxShadow: 2,
          minHeight: 400,
        }}
      >
        <CardContent>
          <Typography variant="h4">Conversions</Typography>
          <Typography
            variant="subtitle1"
            style={{
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Conversions will be tracked here if you have launched a campaign
            with a product tracking link generated on Gigl
          </Typography>
          <Box width={"90%"} margin={"0 auto"}>
            <Box width={"90%"} margin={"0 auto"}>
              {conversions.length > 0 && (
                <BarChart
                  dataset={conversions}
                  xAxis={[{ scaleType: "band", dataKey: "productURL" }]}
                  series={[{ dataKey: "visits", label: "Conversions" }]}
                  height={300}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default Analytics;
