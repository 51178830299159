import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { fetchGet, fetchPost, formatStat } from "../util/helpers";
import toast, { Toaster } from "react-hot-toast";
import {Button, LearnMoreDialog} from "../components";
import { InstagramEmbed } from 'react-social-media-embed';

import launch from "../assets/creatorOutreachAvatar.PNG";
import creatorRates from "../assets/creatorRates.PNG"
import primaryChannels from "../assets/primaryChannels.PNG"
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Avatar,
  DialogContentText,
  Box,
} from "@mui/material";
import outreachEmail from "../assets/Gigl Email Outreach.PNG";

const followerComparator = (a, b) => {
  return followerCountToNumber(a) - followerCountToNumber(b);
};
const followerCountToNumber = (followers) => {
  let amountMultiplier = followers.slice(-1).toLowerCase();
  if (amountMultiplier === "k") {
    amountMultiplier = 1000;
  } else if (amountMultiplier === "m") {
    amountMultiplier = 1000000;
  } else {
    amountMultiplier = 1;
  }
  const amount = parseFloat(followers.slice(0, -1));
  return amount * amountMultiplier;
};
const STATUS_TO_LABEL = {
  "not-contacted": "Not contacted",
  delivered: "Delivered",
  opened: "Opened",
  clicked: "Clicked",
  failed: "Failed",
};

const STATUS_TO_COLOR = {
  "not-contacted": "#ebebeb",
  delivered: "rgb(60 217 139)",
  opened: "#6bc1ff",
  clicked: "#b399ff",
  failed: "red",
};
export default function DiscoveryTable(props) {
  const [data, setData] = useState([]);
  const { campaignId } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmCreatorCardOpen, setConfirmCreatorCardOpen] = useState(false);
  const [creatorData,setCreatorData] = useState({});
  const [creatorName, setCreatorName] = useState("");
  const [creatorEmail, setCreatorEmail] = useState("");
  const [creatorLevelOutreach, setCreatorLevelOutreach] = useState("any");
  const [locationSearch, setLocationSearch] = useState("");
  const [creatorReach, setCreatorReach] = useState("");
  const [creatorGenderSearch, setCreatorGenderSearch] = useState("any");
  const [creatorEthnicitySearch, setCreatorEthnicitySearch] = useState("any");
  const [creatorAudienceTier, setCreatorAudienceTier] = useState("any");
  const [externalDialogOpen, setExternalDialogOpen] = useState(false)
  const [externalCreator, setExternalCreator] = useState(null)
  const [externalLoading, setExternalLoading] = useState(false)


  const [contactStatus, setContactStatus] = useState("any");
  const [nameSearch, setNameSearch] = useState("");
  const fetchOutreach = () => {
    fetchGet(`/brand/outreach?campaignId=${campaignId}`)
      .then((result) => result.result)
      .then((result) => {
        setData(result.map((res) => ({ ...res, id: res.email })));
      });
  };

  const sendEmail = () => {
    fetchPost(`/brand/outreach`, {
      campaignId: campaignId,
      email: creatorEmail,
    })
      .then(() => {
        toast.success(
          `Successfully sent an email to ${creatorName}. Check the status of this email in your discovery table.`,
        );
        setConfirmOpen(false);
        fetchOutreach();
      })
      .catch(() => {
        toast.error(
          `Failed to send an email to ${creatorName}. Please check back later.`,
        );
      });
  };

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", flex: 1 },
      //   { field: "handle", headerName: "Handle", flex: 1,
      //   renderCell: (params) => {
      //     return <a rel="noopener noreferrer" target="_blank" href={params.row.link || `https://www.instagram.com/${params.value}/`}>{params.value}</a>
      //   }
      // },
      {
        field: "followers",
        headerName: "Followers",
        width: 130,
        flex: 0.8,
        valueGetter: (params) => params.value.toUpperCase(),
        sortComparator: followerComparator,
      },
      { field: "industry", headerName: "Industry", width: 130, flex: 2 },
      {
        field: "location",
        headerName: "Location",
        width: 50,
        flex: 1,
        valueGetter: (params) => params.value.join(", "),
      },
      { field: "relevance", headerName: "Relevance", width: 50, flex: 1 },
      {
        field: "viewMore",
        type: "actions",
        headerName: "View More",
        width: 130,
        sortable: false,
        getActions: (params) => [
          <Button
            style={{
              backgroundColor: "#4158D0",
              backgroundImage: "linear-gradient(90deg, #0062ff, #da61ff)",
              borderRadius: 20,
              fontSize: 14,
            }}
            onClick={() => {
              if (params.row.id === "custom-row") {
                setExternalDialogOpen(true)
              }else {
                setConfirmCreatorCardOpen(true);
                setCreatorData(params.row)
                setCreatorReach(params.row.reach);
              }
           
            }}
          >
            More Details
          </Button>,
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    fetchOutreach();
  }, [campaignId]);

  useEffect(() => {
    console.log("nameSearch", nameSearch, externalDialogOpen)
    if (externalDialogOpen && nameSearch) {
      setExternalLoading(true)
      fetchGet(`/brand/external/outreach?username=${nameSearch}`)
      .then((res) => {
        console.log("response", res)
        setExternalCreator(res)
      })
      .finally(() => setExternalLoading(false))
    }
  }, [externalDialogOpen, nameSearch])

  const rows = useMemo(() => {
    const checkName = (row) => {
      if (nameSearch) {
        if (
          row.name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          row.handle.toLowerCase().includes(nameSearch.toLowerCase())
        ) {
          return true;
        }
        return false;
      }
      return true;
    };
    const checkLevel = (row) => {
      const count = followerCountToNumber(row.followers);
      if (creatorLevelOutreach) {
        if (creatorLevelOutreach === "micro" && count <= 50000) {
          return true;
        } else if (
          creatorLevelOutreach === "major" &&
          count > 50000 &&
          count < 150000
        ) {
          return true;
        } else if (creatorLevelOutreach === "celebrity" && count > 150000) {
          return true;
        } else if (creatorLevelOutreach === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    // const checkRelevance = (row) => {
    //   if (creatorLevelRelevance  && row.location) {
    //     if (creatorLevelRelevance === "B2C" && row.relevance.includes("B2C")) {
    //       return true
    //     } else if (
    //       creatorLevelRelevance === "B2BB2C" && row.relevance.includes("B2B")
    //     ) {
    //       return true
    //     } else if (creatorLevelRelevance === "any") {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    //   return true;
    // }
    const checkLocation = (row) => {
      if (locationSearch && row.location) {
        return row.location
          .join(",")
          .toLowerCase()
          .trim()
          .includes(locationSearch.toLowerCase().trim());
      }
      return true;
    };

    const checkStatus = (row) => {
      if (contactStatus) {
        if (contactStatus === "any") {
          return true;
        }
        if (row.status === undefined && contactStatus === "not-contacted") {
          return true;
        }
        if (row.status === contactStatus) {
          return true;
        }
        return false;
      }
      return true;
    };

    const checkGender = (row) => {
      if (creatorGenderSearch) {
        if (creatorGenderSearch === "male" && row.gender === "Male") {
          return true;
        } else if (
          creatorGenderSearch  === "female" &&  row.gender === "Female"
        ) {
          return true;
        } else if (creatorGenderSearch === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    const checkEthnicity = (row) => {
      if (creatorEthnicitySearch) {
        if (creatorEthnicitySearch === "asian" && row.ethnicity === "Asian") {
          return true;
        } else if (
          creatorEthnicitySearch  === "black" &&  row.ethnicity === "Black"
        ) {
          return true;
        } else if (
          creatorEthnicitySearch  === "white/caucasion" &&  row.ethnicity === "White/Caucasian"
        ) {
          return true;
        } else if (
          creatorEthnicitySearch  === "indigenous" &&  row.ethnicity === "Indigenous"
        ) {
          return true;
        } else if (
          creatorEthnicitySearch  === "hispanic" &&  row.ethnicity === "Hispanic"
        ) {
          return true;
        } else if (creatorEthnicitySearch === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    const checkAudienceTier = (row) => {
      if (creatorAudienceTier) {
        if (creatorAudienceTier === "luxury" && row.audience_tier === "l" || row.audience_tier === "Luxury") {
          return true;
        }  else if (creatorAudienceTier === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    const filtered = data.filter((rowData) => {
      return (
        checkStatus(rowData) &&
        checkName(rowData) &&
        checkLevel(rowData) &&
        checkLocation(rowData) &&
        checkGender(rowData) &&
        checkEthnicity(rowData) &&
        checkAudienceTier(rowData)
      );
    });
    if (nameSearch && filtered.length < 10) {
      filtered.push({
        id: 'custom-row',
        name: "",
        followers: "",
        industry: "Can't find the user you're looking for?",
        location: [],
        relevance: "",
        viewMore: ""
    })
    }
    return filtered

  }, [
    creatorLevelOutreach,
    data,
    nameSearch,
    contactStatus,
    locationSearch,
    creatorGenderSearch,
    creatorEthnicitySearch,
    creatorAudienceTier
  ]);


  const closeDialog = () => {
    setConfirmOpen(false);
    setCreatorEmail("");
    setCreatorName("");
  };

  const closeCreatorCardDialog = () => {
    setConfirmCreatorCardOpen(false);
    setCreatorData({})
    setCreatorReach("");

  };
console.log(creatorData)

  return (
    <div style={{ height: 600, width: "100%"}}>

 

<Toaster position="top-center" toastOptions={{ duration: 3000 }} style={{width: 5000}}/>
<Dialog fullWidth maxWidth="lg" open={externalDialogOpen} onClose={() => {setExternalDialogOpen(false); setExternalCreator(null)}}>
  {externalLoading && <DialogTitle>Fetching user data!</DialogTitle> }
 {!externalLoading && externalCreator === null && <DialogContent>
    <DialogTitle>Not Found</DialogTitle>
The user {nameSearch} could not be found!
  </DialogContent>}
  {externalCreator !== null && <DialogContent>
    <DialogTitle>{nameSearch} <Avatar
          fetchPriority="high"
          src={launch}
          style={{ width: 80, height: 80, marginRight: 5, float: "right" }}
        /></DialogTitle>
        <Box sx={{width: "95%", margin: "0 auto"}}>
<DialogContentText>{externalCreator.full_name}</DialogContentText>
<DialogContentText>{externalCreator.biography}</DialogContentText>
<DialogContentText>{formatStat(externalCreator.follower_count)} Followers</DialogContentText>
<DialogContentText>{formatStat(externalCreator.media_count)} Posts</DialogContentText>
<Grid container>

{externalCreator?.medias?.slice(0, 3).map(media => (<Grid item xs={4}> <InstagramEmbed width="100%" key={media.code} url={`https://www.instagram.com/p/${media.code}/`} /></Grid>))}
</Grid>
</Box>

  </DialogContent>}
</Dialog>
      <LearnMoreDialog isDialogOpen={confirmCreatorCardOpen} closeDialog={closeCreatorCardDialog} creator={creatorData}/>
      <TextField
        label="Name/Handle"
        sx={{ height: 25, marginRight: 1, minWidth: 1100, marginBottom: 8 }}
        defaultValue={nameSearch}
        onChange={(e) => {
          setNameSearch(e.target.value);
        }}
        InputProps={{
          style: {
            borderRadius: "50px",
          },
        }}
      />
      <br></br>


      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Creator Level</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Creator Level"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>Micro</MenuItem>
          <MenuItem value={"major"}>Major</MenuItem>
          <MenuItem value={"celebrity"}>Celebrity</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Follower Count</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Follower Count"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>0-50K</MenuItem>
          <MenuItem value={"major"}>50K-150K</MenuItem>
          <MenuItem value={"celebrity"}>150K-1M+ </MenuItem>


        </Select>
      </FormControl>
      <TextField
        label="Location"
        sx={{ height: 25, marginRight: 3, minWidth: 300, marginBottom: 8,  }}
        defaultValue={locationSearch}
        onChange={(e) => {
          setLocationSearch(e.target.value);
        }}
        InputProps={{
          style: {
            borderRadius: "50px",
          },
        }}
      />

<FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Compensation</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Compensation"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>$100-$2500</MenuItem>
          <MenuItem value={"major"}>$2500-$3000</MenuItem>
          <MenuItem value={"celebrity"}>$3000+ </MenuItem>


        </Select>
      </FormControl>

      
      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Gender</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorGenderSearch}
          label="Gender"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorGenderSearch(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>

        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 230, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Audience Tier</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorAudienceTier}
          label="Audience Tier"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorAudienceTier(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"male"}>Luxury Audience</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 300, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Ethnicity</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorEthnicitySearch}
          label="Ethnicity"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorEthnicitySearch(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"asian"}>Asian</MenuItem>
          <MenuItem value={"white/caucasion"}>White/Caucasian</MenuItem>
          <MenuItem value={"black"}>Black</MenuItem>
          <MenuItem value={"hispanic"}>Hispanic</MenuItem>
          <MenuItem value={"indigenous"}>Indigenous</MenuItem>
        </Select>
      </FormControl>

      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
      />
    </div>
  );
}
