import { default as MuiButton } from "@mui/material/Button";

export default function Button(props) {
  const { children, style, variant, ...rest } = props;
  return (
    <MuiButton
      variant={variant || "contained"}
      style={{
        ...(variant !== "text" && { backgroundColor: "black" }),
        fontSize: 18,
        borderRadius: 20,
        color: rest.disabled ? "grey" : "white",
        ...style,
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
}
