import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo, useState, useContext } from "react";
import { fetchGet, fetchPost, fetchPut } from "../util/helpers";
import toast, { Toaster } from "react-hot-toast";
import {Button, LearnMoreDialog} from "../components";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";



import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import outreachEmail from "../assets/Gigl Email Outreach.PNG";

const followerComparator = (a, b) => {
  return followerCountToNumber(a) - followerCountToNumber(b);
};
const followerCountToNumber = (followers) => {
  let amountMultiplier = followers.slice(-1).toLowerCase();
  if (amountMultiplier === "k") {
    amountMultiplier = 1000;
  } else if (amountMultiplier === "m") {
    amountMultiplier = 1000000;
  } else {
    amountMultiplier = 1;
  }
  const amount = parseFloat(followers.slice(0, -1));
  return amount * amountMultiplier;
};
const STATUS_TO_LABEL = {
  "not-contacted": "Not contacted",
  delivered: "Delivered",
  opened: "Opened",
  clicked: "Clicked",
  failed: "Failed",
};

const STATUS_TO_COLOR = {
  "not-contacted": "#ebebeb",
  delivered: "rgb(60 217 139)",
  opened: "#6bc1ff",
  clicked: "#b399ff",
  failed: "red",
};
export default function OutreachTable(props) {
  const [data, setData] = useState([]);
  const { campaignId } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmCreatorCardOpen, setConfirmCreatorCardOpen] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [creatorId, setCreatorId] = useState("");
  const [creatorEmail, setCreatorEmail] = useState("");
  const [creatorLink, setCreatorLink] = useState("");
  const [creatorData, setCreatorData] = useState({});
  const [creatorHandle, setCreatorHandle] = useState("");
  const [creatorIndustry, setCreatorIndustry] = useState("");
  const [creatorLocation, setCreatorLocation] = useState("");
  const [creatorCity, setCreatorCity] = useState("");
  const [creatorRelevance, setCreatorRelevance] = useState("");
  const [creatorFollowers, setCreatorFollowers] = useState("");
  const [creatorReach, setCreatorReach] = useState("");
  const [creatorLevelRelevance, setCreatorLevelRelevance] = useState("any");
  const [creatorLevelOutreach, setCreatorLevelOutreach] = useState("any");
  const [creatorGenderSearch, setCreatorGenderSearch] = useState("any");
  const [creatorEthnicitySearch, setCreatorEthnicitySearch] = useState("any");
  const [creatorAudienceTier, setCreatorAudienceTier] = useState("any");
  const [locationSearch, setLocationSearch] = useState("");
  const navigate = useNavigate();

  const [contactStatus, setContactStatus] = useState("any");
  const [nameSearch, setNameSearch] = useState("");
  const [favoriteOutreach,setFavoriteOutreach] = useState([]);
  const [isFavorite, setIsFavorite] = useState({});
  const { user } = useContext(UserContext);

  const fetchOutreach = () => {
    fetchGet(`/brand/outreach?campaignId=${campaignId}`)
      .then((result) => result.result)
      .then((result) => {
        setData(result.map((res) => ({ ...res, id: res.email })));
      });
  };
  const fetchFavoriteOutreach = async () => {
    const data = await fetchGet(`/brand/outreach/favorite/${user.userDocId}`);
    setFavoriteOutreach(data?.data || []);
  }
  const sendEmail = () => {
    fetchPost(`/brand/outreach`, {
      campaignId: campaignId,
      email: creatorEmail,
    })
      .then(() => {
        toast.success(
          `Successfully sent an email to ${creatorName}. Check the status of this email in your discovery table.`,
        );
        setConfirmOpen(false);
        fetchOutreach();
      })
      .catch(() => {
        toast.error(
          `Failed to send an email to ${creatorName}. Please check back later.`,
        );
      });
  };

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", flex: 1 },
      //   { field: "handle", headerName: "Handle", flex: 1,
      //   renderCell: (params) => {
      //     return <a rel="noopener noreferrer" target="_blank" href={params.row.link || `https://www.instagram.com/${params.value}/`}>{params.value}</a>
      //   }
      // },
      {
        field: "followers",
        headerName: "Followers",
        width: 130,
        flex: 0.8,
        valueGetter: (params) => params.value.toUpperCase(),
        sortComparator: followerComparator,
      },
      { field: "industry", headerName: "Industry", width: 130, flex: 2 },
      {
        field: "location",
        headerName: "Location",
        width: 50,
        flex: 1,
        valueGetter: (params) => params.value.join(", "),
      },
      {
        field: "relevance",
        headerName: "Relevance",
        width: 50,
        flex: 1,
        valueGetter: (params) => params.value.join(", "),
        sortable: false,
      },
      {
        field: "viewMore",
        type: "actions",
        headerName: "View More",
        width: 130,
        sortable: false,
        getActions: (params) => [
          <Button
            style={{
              backgroundColor: "#4158D0",
              backgroundImage: "linear-gradient(90deg, #0062ff, #da61ff)",
              borderRadius: 20,
              fontSize: 14,
            }}
            onClick={() => {
              setConfirmCreatorCardOpen(true);
              setCreatorName(params.row.name);
              setCreatorEmail(params.row.email);
              setCreatorId(params.row.id);
              setCreatorLink(params.row.link);
              setCreatorHandle(params.row.handle);
              setCreatorData(params.row);
              setCreatorIndustry(params.row.industry);
              setCreatorLocation(params.row.location);
              setCreatorCity(params.row.city);
              setCreatorRelevance(params.row.relevance);
              setCreatorFollowers(params.row.followers);
              setCreatorReach(params.row.reach)
              checkIsFavoriteCreator(params.row);
            }}
          >
            More Details
          </Button>,
        ],
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Gigl Outreach",
        width: 130,
        sortable: false,
        getActions: (params) => [
          <Button
            style={{
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
              borderRadius: 20,
              fontSize: 14,
            }}
            onClick={() => {
              setConfirmOpen(true);
              setCreatorName(params.row.name);
              setCreatorEmail(params.row.email);
            }}
          >
            Outreach
          </Button>,
        ],
      },
      {
        field: "status",
        headerName: "Gigl Status",
        width: 300,
        flex: 1.2,
        type: "actions",
        renderCell: (params) => [
          <div style={{ width: "100%" }}>
            <Chip
              label={STATUS_TO_LABEL[params.value]}
              style={{
                width: "100%",
                backgroundColor: STATUS_TO_COLOR[params.value],
              }}
            />
          </div>,
        ],
      },
    ],
    [favoriteOutreach],
  );

  useEffect(() => {
    fetchOutreach();
    fetchFavoriteOutreach();
  }, [campaignId]);

  const rows = useMemo(() => {
    const checkName = (row) => {
      if (nameSearch) {
        if (
          row.name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          row.handle.toLowerCase().includes(nameSearch.toLowerCase())
        ) {
          return true;
        }
        return false;
      }
      return true;
    };
    const checkLevel = (row) => {
      const count = followerCountToNumber(row.followers);
      if (creatorLevelOutreach) {
        if (creatorLevelOutreach === "micro" && count <= 50000) {
          return true;
        } else if (
          creatorLevelOutreach === "major" &&
          count > 50000 &&
          count < 150000
        ) {
          return true;
        } else if (creatorLevelOutreach === "celebrity" && count > 150000) {
          return true;
        } else if (creatorLevelOutreach === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    const checkGender = (row) => {
      if (creatorGenderSearch) {
        if (creatorGenderSearch === "male" && row.gender === "Male") {
          return true;
        } else if (
          creatorGenderSearch  === "female" &&  row.gender === "Female"
        ) {
          return true;
        } else if (creatorGenderSearch === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    const checkEthnicity = (row) => {
      if (creatorEthnicitySearch) {
        if (creatorEthnicitySearch === "asian" && row.ethnicity === "Asian") {
          return true;
        } else if (
          creatorEthnicitySearch  === "black" &&  row.ethnicity === "Black"
        ) {
          return true;
        } else if (
          creatorEthnicitySearch  === "white/caucasion" &&  row.ethnicity === "White/Caucasian"
        ) {
          return true;
        } else if (
          creatorEthnicitySearch  === "indigenous" &&  row.ethnicity === "Indigenous"
        ) {
          return true;
        } else if (
          creatorEthnicitySearch  === "hispanic" &&  row.ethnicity === "Hispanic"
        ) {
          return true;
        } else if (creatorEthnicitySearch === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    const checkAudienceTier = (row) => {
      if (creatorAudienceTier) {
        if (creatorAudienceTier === "luxury" && row.audience_tier === "l" || row.audience_tier === "Luxury") {
          return true;
        }  else if (creatorAudienceTier === "any") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
    const checkLocation = (row) => {
      if (locationSearch && row.location) {
        return row.location
          .join(",")
          .toLowerCase()
          .trim()
          .includes(locationSearch.toLowerCase().trim());
      }
      return true;
    };

    const checkStatus = (row) => {
      if (contactStatus) {
        if (contactStatus === "any") {
          return true;
        }
        if (row.status === undefined && contactStatus === "not-contacted") {
          return true;
        }
        if (row.status === contactStatus) {
          return true;
        }
        return false;
      }
      return true;
    };
    return data.filter((rowData) => {
      return (
        checkStatus(rowData) &&
        checkName(rowData) &&
        checkLevel(rowData) &&
        checkLocation(rowData) &&
        checkGender(rowData) &&
        checkEthnicity(rowData) &&
        checkAudienceTier(rowData)
      );
    });
  }, [
    creatorLevelOutreach,
    data,
    nameSearch,
    contactStatus,
    locationSearch,
    creatorGenderSearch,
    creatorEthnicitySearch,
    creatorAudienceTier
  ]);

  const closeDialog = () => {
    setConfirmOpen(false);
    setCreatorEmail("");
    setCreatorName("");
  };

  const closeCreatorCardDialog = () => {
    setConfirmCreatorCardOpen(false);
    setCreatorEmail("");
    setCreatorName("");
    setCreatorLink("");
    setCreatorData({});
    setCreatorHandle("");
    setCreatorLocation("");
    setCreatorCity("");
    setCreatorRelevance("");
    setCreatorFollowers("");
    setCreatorReach("");
    setIsFavorite({});
  };

  const checkIsFavoriteCreator = (selectedCreator) => {
    const favoriteData = favoriteOutreach.filter((data)=>data.outreach_emailid === selectedCreator.email);
    setIsFavorite(favoriteData[0]||{});
  }
  
  const handleFavorite = async (status) => {
    if(!isFavorite.id){
      const result = await fetchPost("/brand/outreach/favorite",{
        "outreach_emailid":creatorData.email,
        "favorite_status":status
      });
      const resultData = {...result.data};
      const newList = [...favoriteOutreach];
      newList.push({...resultData});

      setFavoriteOutreach([...newList]);
      setIsFavorite({...resultData});
    }else{
      const result = await fetchPut(`/brand/outreach/favorite/${isFavorite.id}`,{...isFavorite,"favorite_status":status});
      const resultData = {...result.data};
      const newList = favoriteOutreach.map((data)=>data.id === isFavorite.id? {...resultData}:{...data});
      setFavoriteOutreach([...newList]);
      setIsFavorite({...resultData});
    }
  }

  return (
    <div style={{ height: 600, width: "100%" }}>
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} />
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          Outreach To This Creator Using Gigl's Customized Email Template
        </DialogTitle>
        <DialogContent>
          By outreaching to a creator, you are inviting them to apply for your
          campaign. If this creator is interested in working with you, they will
          show up in your applications section above. Are you sure you want to
          outreach to {creatorName}?
          <br />
          <br />
          <img
            src={outreachEmail}
            height="100%"
            style={{
              maxHeight: 500,
              maxWidth: 550,
            }}
          ></img>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>

          <Button
            onClick={sendEmail}
            style={{
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Toaster
        position="top-center"
        toastOptions={{ duration: 3000 }}
        style={{ width: 5000 }}
      />
      <LearnMoreDialog closeDialog={closeCreatorCardDialog} creator={creatorData}  isDialogOpen={confirmCreatorCardOpen} isFavorite={isFavorite} handleFavorite={handleFavorite}/>

      <TextField
        label="Name/Handle Search"
        sx={{ height: 25, marginRight: 1, minWidth: 1200, marginBottom: 8 }}
        defaultValue={nameSearch}
        onChange={(e) => {
          setNameSearch(e.target.value);
        }}
        InputProps={{
          style: {
            borderRadius: "50px",
          },
        }}
      />
      <br></br>


      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 200, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Creator Level</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Creator Level"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>Micro</MenuItem>
          <MenuItem value={"major"}>Major</MenuItem>
          <MenuItem value={"celebrity"}>Celebrity</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 200, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Follower Count</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Follower Count"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>0-50K</MenuItem>
          <MenuItem value={"major"}>50K-150K</MenuItem>
          <MenuItem value={"celebrity"}>150K-1M+ </MenuItem>


        </Select>
      </FormControl>

      <TextField
        label="Country Location"
        sx={{ height: 25, marginRight: 3, minWidth: 300, marginBottom: 8,  }}
        defaultValue={locationSearch}
        onChange={(e) => {
          setLocationSearch(e.target.value);
        }}
        InputProps={{
          style: {
            borderRadius: "50px",
          },
        }}
      />

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 200, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Compensation</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorLevelOutreach}
          label="Compensation"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelOutreach(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"micro"}>$100-$2500</MenuItem>
          <MenuItem value={"major"}>$2500-$3000</MenuItem>
          <MenuItem value={"celebrity"}>$3000+ </MenuItem>


        </Select>
      </FormControl>


      {/* <FormControl
        sx={{ height: 25, marginRight: 1, minWidth: 200, marginBottom: 8 }}
      >
        <InputLabel >Relevance</InputLabel>
        <Select
          labelId="creator-relevance-label"
          id="creator-relevance-select"
          value={creatorLevelRelevance}
          label="Creator Relevance"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorLevelRelevance(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"B2C"}>B2C</MenuItem>
          <MenuItem value={"B2BB2C"}>B2B</MenuItem>

        </Select>
      </FormControl> */}
      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 200, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Gigl Outreach</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={contactStatus}
          label="Creator Level"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setContactStatus(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"not-contacted"}>Not contacted</MenuItem>
          <MenuItem value={"delivered"}>Delivered</MenuItem>
          <MenuItem value={"opened"}>Opened</MenuItem>
          <MenuItem value={"clicked"}>Clicked</MenuItem>
          <MenuItem value={"failed"}>Failed</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 200, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Gender</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorGenderSearch}
          label="Gender"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorGenderSearch(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>

        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 300, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Ethnicity</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorEthnicitySearch}
          label="Ethnicity"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorEthnicitySearch(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"asian"}>Asian</MenuItem>
          <MenuItem value={"white/caucasion"}>White/Caucasian</MenuItem>
          <MenuItem value={"black"}>Black</MenuItem>
          <MenuItem value={"hispanic"}>Hispanic</MenuItem>
          <MenuItem value={"indigenous"}>Indigenous</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{ height: 25, marginRight: 3, minWidth: 200, marginBottom: 8 }}
      >
        <InputLabel id="outreach-level-select-label">Audience Tier</InputLabel>
        <Select
          labelId="outreach-level-select-label"
          id="outreach-level-select"
          value={creatorAudienceTier}
          label="Audience Tier"
          variant="outlined"
          sx={{ borderRadius: 200 }}
          autoWidth
          onChange={(e) => setCreatorAudienceTier(e.target.value)}
        >
          <MenuItem value={"any"}>Any</MenuItem>
          <MenuItem value={"male"}>Luxury Audience</MenuItem>
        </Select>
      </FormControl>

      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
      />
    </div>
  );
}
