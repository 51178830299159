import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  Avatar,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Slider,
  Input,
  InputAdornment,
  Switch,
  FormControlLabel,
  Grow,
  Box,
  TextareaAutosize,
  Paper,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import {
  fetchGet,
  fetchPost,
  generateMockAnalytics,
  handleUpload,
  idGenerator,
  transformFirebaseTime,
} from "../util/helpers";
import LineChart from "../components/LineChart";
import { FileUpload, LeftSideBar } from "../components";
import host from "../assets/host.PNG";
import { ReactComponent as EmptyAnalytics } from "../assets/empty-analytics.svg";
import { INDUSTRIES } from "../util/constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useMediaQuery from "@mui/material";
import blogpic3Measuring from "../assets/measuring_influencer_marketing.PNG";
import multipleCreators from "../assets/multiplecreators.PNG";


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function QA(props) {
  const { question, answer } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>{answer}</AccordionDetails>
    </Accordion>
  );
}

const COUNTRIES = [
  "All",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "South Korea",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia (formerly Macedonia)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export default function Campaigns() {
  const [rightGridHeight, setRightGridHeight] = useState(0);
  const rightGridRef = useRef(null);
  const [campaigns, setCampaigns] = useState([]);
  const navigate = useNavigate();
  const [newCampaignOpen, setNewCampaignOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { setIsLoading, setLoadingText } = useContext(LoadingContext);
  const [newCampaignData, setNewCampaignData] = useState({});
  const modalRef = useRef();
  const [requirements, setRequirements] = useState({});
  const [modalWidth, setModalWidth] = useState(752);
  const [newCampaignImage, setNewCampaignImage] = useState();
  const [invalidInput, setInvalidInput] = useState({});
  const [isNegotiable, setIsNegotiable] = useState(true);
  const [worklog, setWorklog] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const fetchCampaigns = async () => {
    const results = await fetchGet("/brand/campaigns", setIsLoading);
    setCampaigns(results.result);
  };
  const fetchAnalytics = async () => {
    const results = await fetchGet("/brand/analytics", setIsLoading);
    setAnalytics(results.result);
  };
  useEffect(() => {
    if (rightGridRef.current) {
      const updateHeight = () => {
        setRightGridHeight(rightGridRef.current.clientHeight);
      };

      updateHeight();

      window.addEventListener('resize', updateHeight);

      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }
  }, [rightGridRef.current]);
  useEffect(() => {
    if (user.id) {
      fetchCampaigns();
      fetchAnalytics();
    }
  }, [user]);

  useEffect(() => {
    if (user.id && !user.isSetup) {
      navigate("/setup");
    }
  }, [user]);

  const handleUpdateNewCampaign = (e) => {
    setNewCampaignData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCloseModal = () => {
    setNewCampaignOpen(false);
  };
  const upload = async () => {
    if (newCampaignImage) {
      const imagePath = idGenerator();
      const imageURL = await handleUpload(
        newCampaignImage,
        "brand-images",
        imagePath,
      );
      return imageURL;
    } else {
      alert("No image found!");
      return null;
    }
  };

  const handleCreateCampaign = async (event) => {
    event.preventDefault();
    const imageURL = await upload();
    if (!imageURL) {
      return;
    }
    await fetchPost(
      "/brand/campaigns",
      {
        ...newCampaignData,
        image: imageURL,
      },
      setIsLoading,
    );

    setNewCampaignOpen(false);
    fetchCampaigns();
  };

  const getGeneratedText = () => {
    if (
      !newCampaignData.description ||
      newCampaignData.description.trim() === ""
    ) {
      alert("Please fill out your brand name and description first!");
      return;
    }
    setLoadingText("Generating talking points");
    fetchPost(
      "/brand/talking_points/generate",
      {
        brandName: newCampaignData.brand,
        campaignDetails: newCampaignData.description,
      },
      setIsLoading,
    ).then((res) => {
      console.log("res", res);
      setNewCampaignData((prev) => ({
        ...prev,
        talking_points: res.message.trim(),
      }));
    });
  };

  useEffect(() => {
    if (newCampaignData.is_paid === false) {
      setIsNegotiable(false);
      setNewCampaignData((prev) => ({
        ...prev,
        budget: -1,
      }));
    }
  }, [newCampaignData.is_paid]);

  const mockViews = generateMockAnalytics(
    new Date("2023-03-01"),
    new Date("2023-04-01"),
  );
  const mockEng = generateMockAnalytics(
    new Date("2023-03-01"),
    new Date("2023-04-01"),
  );
  const mockEngRate = generateMockAnalytics(
    new Date("2023-03-01"),
    new Date("2023-04-01"),
  );
  const mockImp = generateMockAnalytics(
    new Date("2023-03-01"),
    new Date("2023-04-01"),
  );
  console.log("campaigns", campaigns);
  return (
    <>
      <Dialog open={newCampaignOpen} fullWidth onClose={handleCloseModal}>
        <DialogTitle>Create a Campaign</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleCreateCampaign}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Campaign Name
            </Typography>
            <TextField
              fullWidth
              required
              name="name"
              onChange={handleUpdateNewCampaign}
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Brand
            </Typography>
            <TextField
              fullWidth
              name="brand"
              required
              onChange={handleUpdateNewCampaign}
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Brand Image
            </Typography>
            <FileUpload
              containerProps={{
                style: { width: "100%", marginBottom: 20, textAlign: "center" },
              }}
              handleFilesChange={(files) => {
                if (files !== null && files?.length > 0) {
                  setNewCampaignImage(files[0]);
                }
              }}
            />
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Industry Focus
            </Typography>
            <FormControl fullWidth required>
              <Select
                value={newCampaignData.industry || []}
                name="industry"
                required
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCampaignData((prev) => ({
                    ...prev,
                    [e.target.name]:
                      typeof value === "string" ? value.split(",") : value,
                  }));
                }}
                multiple
              >
                {INDUSTRIES.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Campaign Details
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={4}
              name="description"
              onChange={handleUpdateNewCampaign}
              required
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Deliverables
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={4}
              name="deliverables"
              required
              onChange={handleUpdateNewCampaign}
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
              }}
            >
              Talking Points
            </Typography>
            <Button
              variant="caption"
              style={{
                textAlign: "left",
                marginTop: 10,
                marginBottom: 10,
                fontSize: 14,
                fontWeight: 600,
              }}
              onClick={getGeneratedText}
            >
              Get AI generated talking points
            </Button>
            <TextField
              fullWidth
              multiline
              minRows={4}
              name="talking_points"
              required
              onChange={handleUpdateNewCampaign}
              value={newCampaignData.talking_points}
            ></TextField>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Platforms
            </Typography>

            <FormControl fullWidth required>
              <Select
                value={newCampaignData.platforms || []}
                name="platforms"
                required
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCampaignData((prev) => ({
                    ...prev,
                    [e.target.name]:
                      typeof value === "string" ? value.split(",") : value,
                  }));
                }}
                multiple
              >
                {[
                  { label: <>TikTok</>, value: "tiktok" },
                  { label: "YouTube", value: "youtube" },
                  { label: "Instagram", value: "instagram" },
                  { label: "X (Formerly Twitter)", value: "x" },
                  { label: "LinkedIn", value: "LinkedIn" },
                ].map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Level of Creator
            </Typography>

            <FormControl fullWidth required>
              <Select
                value={newCampaignData.celebrity || []}
                name="celebrity"
                required
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCampaignData((prev) => ({
                    ...prev,
                    [e.target.name]:
                      typeof value === "string" ? value.split(",") : value,
                  }));
                }}
                multiple
              >
                {[
                  { label: "Micro", value: "micro" },
                  { label: "Major", value: "major" },
                  { label: "Celebrity", value: "celebrity" },
                ].map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Region
            </Typography>
            <FormControl fullWidth required>
              <Select
                value={newCampaignData.region || []}
                name="region"
                required
                onChange={(e) => {
                  const value = e.target.value;
                  setNewCampaignData((prev) => ({
                    ...prev,
                    [e.target.name]:
                      typeof value === "string" ? value.split(",") : value,
                  }));
                }}
                multiple
              >
                {COUNTRIES.map((country) => ({
                  label: country,
                  value: country,
                })).map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Is this a paid partnership campaign?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  value={newCampaignData.is_paid || false}
                  defaultChecked={newCampaignData.is_paid || false}
                  onChange={(e) => {
                    setNewCampaignData((prev) => ({
                      ...prev,
                      is_paid: e.target.checked,
                    }));
                  }}
                />
              }
              label={newCampaignData.is_paid ? "Yes" : "No"}
            />
            {newCampaignData.is_paid && (
              <>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: 800,
                    textAlign: "left",
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  Budget Per Influencer
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      value={isNegotiable}
                      defaultChecked={isNegotiable}
                      onChange={(e) => setIsNegotiable(e.target.checked)}
                    />
                  }
                  label={
                    isNegotiable
                      ? "Budget is negotiable"
                      : "Budget is not negotiable"
                  }
                />{" "}
              </>
            )}
            {newCampaignData.is_paid && !isNegotiable && (
              <Input
                id="standard-adornment-amount"
                fullWidth
                name="budget"
                required
                onChange={handleUpdateNewCampaign}
                type="number"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            )}
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Is this a merchandise or affiliate campaign?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  value={newCampaignData.is_merchandise || false}
                  defaultChecked={newCampaignData.is_merchandise || false}
                  onChange={(e) => {
                    setNewCampaignData((prev) => ({
                      ...prev,
                      is_merchandise: e.target.checked,
                    }));
                  }}
                />
              }
              label={newCampaignData.is_merchandise ? "Yes" : "No"}
            />
            {newCampaignData.is_merchandise && (
              <TextareaAutosize
                name="merchandise_details"
                required
                onChange={handleUpdateNewCampaign}
                fullWidth
                style={{ minWidth: "100%", maxWidth: "100%", padding: 10 }}
                minRows={5}
                placeholder="Share more details about the merchandise and/or its monetary value"
              />
            )}
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Is this campaign for a creator event?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  value={newCampaignData.is_event || false}
                  defaultChecked={newCampaignData.is_event || false}
                  onChange={(e) => {
                    setNewCampaignData((prev) => ({
                      ...prev,
                      is_event: e.target.checked,
                    }));
                  }}
                />
              }
              label={newCampaignData.is_event ? "Yes" : "No"}
            />
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Do you have specific creators you are working with on this
              campaign?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  value={newCampaignData.specific_creators || false}
                  defaultChecked={newCampaignData.specific_creators || false}
                  onChange={(e) => {
                    setNewCampaignData((prev) => ({
                      ...prev,
                      specific_creators: e.target.checked,
                    }));
                  }}
                />
              }
              label={newCampaignData.specific_creators ? "Yes" : "No"}
            />
            {newCampaignData.specific_creators && (
              <>
                <Typography>Please put in their creator codes:</Typography>
                <TextField
                  fullWidth
                  name="creator_codes"
                  onChange={handleUpdateNewCampaign}
                ></TextField>
              </>
            )}
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Written Concept Due Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100%" }}
                onChange={(value) => {
                  handleUpdateNewCampaign({
                    target: {
                      name: "written_due",
                      value: value.format("YYYY-MM-DD"),
                    },
                  });
                }}
              />
            </LocalizationProvider>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Draft Due Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(value) => {
                  handleUpdateNewCampaign({
                    target: {
                      name: "draft_due",
                      value: value.format("YYYY-MM-DD"),
                    },
                  });
                }}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 800,
                textAlign: "left",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              Final Due Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} required>
              <DatePicker
                required
                sx={{ width: "100%" }}
                onChange={(value) => {
                  handleUpdateNewCampaign({
                    target: {
                      name: "final_due",
                      value: value.format("YYYY-MM-DD"),
                    },
                  });
                }}
              />
            </LocalizationProvider>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                style={{ display: "flex", marginRight: 0, marginTop: 20 }}
              >
                Create
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>

      <div
        style={{
          width: "100%",
          height: "100%",
          background: "black",
          textAlign: "left",
          marginTop: 5,
          color: "white",
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        <Typography
          variant="h2"
          component="div"
          style={{ color: "white", marginLeft: "15vw" }}
        >
          My Dashboard
        </Typography>
      </div>

      <Grid container>
        <div style={{ width: "90vw" }}>
          <Grid container>
            <Grid item xs={2} style={{ textAlign: "left",height:rightGridHeight }}>
              <LeftSideBar />
            </Grid>
              <Grid item xs={6} style={{ textAlign: "left", marginTop: 50 }} ref={rightGridRef}>
                <Typography
                  variant="h4"
                  style={{ marginBottom: 20, textAlign: "left", fontWeight: 600 }}
                >
                  Active Campaigns
                </Typography>

                <div
                  style={{
                    height: 400,
                    maxHeight: 400,
                    overflow: "auto",
                    minHeight: 200,
                  }}
                >
                  <Button
                    style={{
                      border: "2px solid black",
                      borderRadius: 10,
                      width: "80%",
                      marginBottom: 20,
                    }}
                    onClick={() => setNewCampaignOpen(true)}
                  >
                    Launch a New Campaign +
                  </Button>
                  {campaigns
                    .sort(
                      (a, b) =>
                        transformFirebaseTime(b.submittedAt) -
                        transformFirebaseTime(a.submittedAt),
                    )
                    .map((campaign) => {
                      return (
                        <div key={campaign.id}>
                          <Button
                            onClick={() => navigate(`/campaigns/${campaign.id}`)}
                            style={{
                              backgroundColor: "#fffafa",
                              color: "black",
                              border: "2px solid black",
                              borderRadius: 10,
                              width: "80%",
                              marginBottom: 20,
                            }}
                          >
                            {campaign.name || `Campaign ${campaign.brand}`}
                          </Button>
                          <br />
                        </div>
                      );
                    })}
                </div>

                <div
                  style={{
                    maxWidth: "40vw",
                    textAlign: "left",
                    height: 320,
                    marginTop: 80,
                    height: 500,
                  }}
                  item
                  xs={4}
                >
                  <Grid
                    style={{
                      minHeight: 5,
                      backgroundColor: "black",
                      width: "90%",
                      marginBottom: 30,
                    }}
                  ></Grid>

                  <Typography
                    style={{
                      fontSize: 18,
                      marginBottom: 15,
                      width: "40vw",
                      fontWeight: 600,
                    }}
                  >
                    Check Out the Gigl Blog For the Latest Insights
                  </Typography>

                  <Typography
                    style={{ fontSize: 30, fontWeight: 600, width: "40vw" }}
                  >
                    How to measure influencer marketing: Key Tips & Strategies
                  </Typography>

                  <p
                    style={{
                      width: "35vw",
                      fontSize: 15,
                      marginTop: 10,
                    }}
                  >
                    There are many ways to measure the success of your influencer
                    marketing campaign, and they largely depend on your goals - Is
                    it brand awareness? Sales? Engagement...
                  </p>
                  <p
                    style={{
                      width: "80vw",
                      fontSize: 10,
                      marginTop: 10,
                      fontStyle: "italic",
                    }}
                  >
                    {" "}
                    - Gigl Influencer Marketing Expert
                  </p>

                  <Button
                    style={{
                      display: "block",
                      backgroundColor: "black",
                      // backgroundImage:
                      //   "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                      fontSize: 15,
                      marginTop: 40,
                      marginRight: 300,
                      marginLeft: 0,
                      width: "25vw",
                      borderRadius: 6
                    }}
                    onClick={() => navigate("/blog/blogarticle3")}
                  >
                    Read the Full Article
                  </Button>

                  <Typography
                    style={{
                      fontSize: 18,
                      marginBottom: 15,
                      width: "40vw",
                      fontWeight: 600,
                      marginTop: 75,
                    }}
                  >
                    Start Discovering Creators For You +
                  </Typography>

                  <img
                  src={multipleCreators}
                  style={{
                    borderRadius: 20,
                    marginTop: 10,
                    width: "80%",
                    height: "15%"
                    
                  }}
                ></img>

                </div>


              </Grid>
              <Grid item xs={4} style={{ marginTop: 50 }}>
                <Typography
                  variant="h4"
                  style={{ marginBottom: 20, textAlign: "left", fontWeight: 600 }}
                >
                  Work log
                </Typography>
                <Timeline position="left">
                  {worklog.length === 0 && (
                    <>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          color="text.secondary"
                        >
                          Just Now
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot></TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Card sx={{ width: "100%", minWidth: 300, borderRadius: 4, backgroundColor: "#fffafa" }}>
                            <CardContent>
                              <Avatar
                                sx={{ width: 60, height: 60, float: "left" }}
                                src={host}
                              ></Avatar>
                              <Typography variant="h4">Gigl Host</Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 800 }}
                              >
                                Message
                              </Typography>
                              <br />
                              <Typography variant="body2">
                                Welcome to Gigl! Launch your first campaign today
                                and start partnering with amazing creators.
                              </Typography>
                            </CardContent>
                          </Card>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          color="text.secondary"
                        >
                          Just Now
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot></TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Card sx={{ width: "100%", minWidth: 300, borderRadius: 4, backgroundColor: "#fffafa"  }}>
                            <CardContent>
                              <Avatar
                                sx={{ width: 60, height: 60, float: "left" }}
                                src={host}
                              ></Avatar>
                              <Typography variant="h4">Gigl Host</Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 800 }}
                              >
                                Message
                              </Typography>
                              <br />
                              <Typography variant="body2">
                                Once you launch your first campaign, you will be
                                able to see and track your analytics.
                              </Typography>
                            </CardContent>
                          </Card>
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  )}
                  {worklog.map((log) => {
                    return (
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0" }}
                          align="right"
                          variant="body2"
                          color="text.secondary"
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot></TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Card sx={{ width: "100%", minWidth: 300 }}>
                            <CardContent>
                              <Avatar
                                sx={{ width: 60, height: 60, float: "left" }}
                                src={log.profileImage}
                              ></Avatar>
                              <Typography variant="h4">
                                {log.senderName}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 800 }}
                              >
                                Message
                              </Typography>
                              <br />
                              <Typography variant="body2">
                                {log.message}
                              </Typography>
                            </CardContent>
                          </Card>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>

                <img
                  src={blogpic3Measuring}
                  height="100%"
                  style={{
                    borderRadius: 20,
                    maxHeight: 380,
                    marginTop: 20,
                    
                  }}
                ></img>

                

                 <Button
                                  basic
                                  style={{
                                    marginTop: 75,
                                    borderRadius: 6,
                                    width: "80%",
                                    height: 60,
                                    marginBottom: 30,
                                    backgroundColor: "#4158D0",
                                    backgroundImage:
                                      "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                                    fontSize: 15,
                                  }}
                                  onClick={() => navigate("/campaigns/creatordiscovery")}
                                >
                                  Visit Creator Discovery
                 </Button>
              </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}
