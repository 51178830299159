import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Grid,
  Avatar,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Slider,
  Input,
  InputAdornment,
  Switch,
  FormControlLabel,
  Grow,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";
import {
  fetchGet,
  fetchPost,
  generateMockAnalytics,
  handleUpload,
  idGenerator,
  transformFirebaseTime,
} from "../util/helpers";
import {LineChart,LeftSideBar} from "../components";
import host from "../assets/host.jpg";
import { ReactComponent as EmptyAnalytics } from "../assets/empty-analytics.svg";
import { INDUSTRIES } from "../util/constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import useMediaQuery from "@mui/material";
import blogpic3 from "../assets/blogpic3.PNG";

export default function AnalyticsDashboard() {
  const [rightGridHeight, setRightGridHeight] = useState(0);
  const rightGridRef = useRef(null);
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [newCampaignOpen, setNewCampaignOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { setIsLoading, setLoadingText } = useContext(LoadingContext);
  const [newCampaignData, setNewCampaignData] = useState({});
  const modalRef = useRef();
  const [requirements, setRequirements] = useState({});
  const [modalWidth, setModalWidth] = useState(752);
  const [newCampaignImage, setNewCampaignImage] = useState();
  const [invalidInput, setInvalidInput] = useState({});
  const [isNegotiable, setIsNegotiable] = useState(true);
  const [worklog, setWorklog] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const fetchCampaigns = async () => {
    const results = await fetchGet("/brand/campaigns", setIsLoading);
    setCampaigns(results.result);
  };
  const fetchAnalytics = async () => {
    const results = await fetchGet("/brand/analytics", setIsLoading);
    setAnalytics(results.result);
  };
  useEffect(() => {
    if (rightGridRef.current) {
      const updateHeight = () => {
        setRightGridHeight(rightGridRef.current.clientHeight);
      };

      updateHeight();

      window.addEventListener('resize', updateHeight);

      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }
  }, [rightGridRef.current]);
  useEffect(() => {
    if (user.id) {
      fetchCampaigns();
      fetchAnalytics();
    }
  }, [user]);

  useEffect(() => {
    if (user.id && !user.isSetup) {
      navigate("/setup");
    }
  }, [user]);

  const handleUpdateNewCampaign = (e) => {
    setNewCampaignData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCloseModal = () => {
    setNewCampaignOpen(false);
  };
  const upload = async () => {
    if (newCampaignImage) {
      const imagePath = idGenerator();
      const imageURL = await handleUpload(
        newCampaignImage,
        "brand-images",
        imagePath,
      );
      return imageURL;
    } else {
      alert("No image found!");
      return null;
    }
  };

  const handleCreateCampaign = async (event) => {
    event.preventDefault();
    const imageURL = await upload();
    if (!imageURL) {
      return;
    }
    await fetchPost(
      "/brand/campaigns",
      {
        ...newCampaignData,
        image: imageURL,
      },
      setIsLoading,
    );

    setNewCampaignOpen(false);
    fetchCampaigns();
  };

  const getGeneratedText = () => {
    if (
      !newCampaignData.description ||
      newCampaignData.description.trim() === ""
    ) {
      alert("Please fill out your brand name and description first!");
      return;
    }
    setLoadingText("Generating talking points");
    fetchPost(
      "/brand/talking_points/generate",
      {
        brandName: newCampaignData.brand,
        campaignDetails: newCampaignData.description,
      },
      setIsLoading,
    ).then((res) => {
      console.log("res", res);
      setNewCampaignData((prev) => ({
        ...prev,
        talking_points: res.message.trim(),
      }));
    });
  };

  useEffect(() => {
    if (newCampaignData.is_paid === false) {
      setIsNegotiable(false);
      setNewCampaignData((prev) => ({
        ...prev,
        budget: -1,
      }));
    }
  }, [newCampaignData.is_paid]);

  const mockViews = generateMockAnalytics(
    new Date("2023-03-01"),
    new Date("2023-04-01"),
  );
  const mockEng = generateMockAnalytics(
    new Date("2023-03-01"),
    new Date("2023-04-01"),
  );
  const mockEngRate = generateMockAnalytics(
    new Date("2023-03-01"),
    new Date("2023-04-01"),
  );
  const mockImp = generateMockAnalytics(
    new Date("2023-03-01"),
    new Date("2023-04-01"),
  );

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "black",
          textAlign: "left",
          marginTop: 5,
          color: "white",
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        <Typography
          variant="h2"
          component="div"
          style={{ color: "white", marginLeft: "15vw" }}
        >
          Analytics Dashboard
        </Typography>
      </div>

      <Grid container>
        <div style={{ width: "90vw" }}>
          <Grid container>
          <Grid item xs={2} style={{ textAlign: "left",height:rightGridHeight }}>
              <LeftSideBar />
            </Grid>
            <Grid item xs={9} style={{ marginTop: 50 }} ref={rightGridRef}>
              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: "left", marginBottom: 10, fontWeight: 600 }}
              >
                Analytics, Powered by AI
              </Typography>

              {!analytics ||
                (analytics.length === 0 && (
                  <>
                    <Typography
                      variant="body1"
                      component="div"
                      style={{
                        textAlign: "center",
                        marginTop: 5,
                        marginBottom: 40,
                      }}
                    >
                      Track cross-campaign wide analytics below.
                      <br></br>
                      For creator specific views, engagement metrics, and
                      conversions
                      <br></br>
                      visit the creator workstream page, available once you have
                      partnered with a creator.
                    </Typography>

                    <Card
                      sx={{
                        width: "60vw",
                        minWidth: 500,
                        margin: "0 auto",
                        boxShadow: 2,
                        minHeight: 400,
                      }}
                    >
                      <CardContent>
                        <Typography variant="h4">Views</Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            textAlign: "left",
                            marginTop: 10,
                          }}
                        >
                          Track campaign wide views here once your campaign is
                          live
                        </Typography>
                        <Box width={"90%"} margin={"0 auto"}></Box>
                      </CardContent>
                    </Card>

                    <Card
                      sx={{
                        width: "60vw",
                        minWidth: 500,
                        margin: "0 auto",
                        boxShadow: 2,
                        minHeight: 400,
                        marginTop: 10,
                      }}
                    >
                      <CardContent>
                        <Typography variant="h4">Engagement</Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            textAlign: "left",
                            marginTop: 10,
                          }}
                        >
                          Track campaign wide engagement here once your campaign
                          is live
                        </Typography>
                        <Box width={"90%"} margin={"0 auto"}></Box>
                      </CardContent>
                    </Card>
                  </>
                ))}

              {Object.keys(analytics).length === 0 && (
                <div style={{ marginBottom: 10 }}>
                  <Typography>
                    Get your custom analytics here, and start measuring your
                    performance across campaigns.
                    <br></br>
                    For creator specific views, engagement metrics, and
                    conversions visit your campaign page.
                  </Typography>

                  <br />
                  {/* <EmptyAnalytics style={{ width: 500, margin: "0 auto" }} /> */}

                  <Card
                    sx={{
                      width: "70vw",
                      minWidth: 700,
                      boxShadow: `1px 1px 14px 1px rgb(213, 126, 235)`,
                      minHeight: 400,
                      marginTop: 8,
                    }}
                  >
                    <CardContent>
                      <Typography variant="h4">Views</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          textAlign: "left",
                          marginTop: 10,
                        }}
                      >
                        Track campaign wide views here once your campaign is
                        live
                      </Typography>
                      <Box width={"90%"} margin={"0 auto"}></Box>
                    </CardContent>
                  </Card>

                  <Card
                    sx={{
                      width: "70vw",
                      minWidth: 700,
                      boxShadow: `1px 1px 14px 1px rgb(213, 126, 235)`,
                      minHeight: 400,
                      marginTop: 10,
                    }}
                  >
                    <CardContent>
                      <Typography variant="h4">Engagement</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{
                          textAlign: "left",
                          marginTop: 10,
                        }}
                      >
                        Track campaign wide engagement here once your campaign
                        is live
                      </Typography>
                      <Box width={"90%"} margin={"0 auto"}></Box>
                    </CardContent>
                  </Card>
                </div>
              )}
              {Object.keys(analytics).length > 0 && (
                <Grid
                  container
                  gap={15}
                  direction="row"
                  alignItems="flex-start"
                >
                  <Grid item xs={5}>
                    <Card sx={{ width: "90%", height: 300, padding: 0 }}>
                      <Typography variant="h5" style={{ marginLeft: 10 }}>
                        Collective Views
                      </Typography>
                      <LineChart
                        width={500}
                        height={500}
                        data={mockViews.slice(1).slice(-14)}
                        keyToLabel={{
                          youtubeViewCount: "Youtube",
                          instagramViewCount: "Instagram",
                          tiktokViewCount: "Tiktok",
                          facebookViewCount: "Facebook",
                          total: "Total",
                        }}
                      />
                    </Card>
                    <br />
                    <Card sx={{ width: "90%", height: 300 }}>
                      <Typography variant="h5" style={{ marginLeft: 10 }}>
                        Engagement Rate
                      </Typography>
                      <LineChart
                        width={500}
                        height={500}
                        data={mockEngRate.slice(1).slice(-14)}
                        keyToLabel={{
                          youtubeViewCount: "Youtube",
                          instagramViewCount: "Instagram",
                          tiktokViewCount: "Tiktok",
                          facebookViewCount: "Facebook",
                          total: "Total",
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={5}>
                    <Card sx={{ width: "90%", height: 300 }}>
                      <Typography variant="h5" style={{ marginLeft: 10 }}>
                        Engagement
                      </Typography>
                      <LineChart
                        width={500}
                        height={500}
                        data={mockEng.slice(1).slice(-14)}
                        keyToLabel={{
                          youtubeViewCount: "Youtube",
                          instagramViewCount: "Instagram",
                          tiktokViewCount: "Tiktok",
                          facebookViewCount: "Facebook",
                          total: "Total",
                        }}
                      />
                    </Card>
                    <br />
                    <Card sx={{ width: "90%", height: 300 }}>
                      <Typography variant="h5" style={{ marginLeft: 10 }}>
                        Impressions
                      </Typography>
                      <LineChart
                        width={500}
                        height={500}
                        data={mockImp.slice(1).slice(-14)}
                        keyToLabel={{
                          youtubeViewCount: "Youtube",
                          instagramViewCount: "Instagram",
                          tiktokViewCount: "Tiktok",
                          facebookViewCount: "Facebook",
                          total: "Total",
                        }}
                      />
                    </Card>
                  </Grid>
                </Grid>
              )}
              <br />
              <br />

              <Typography variant="h4">Creator Compensation Data</Typography>
              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                }}
              >
                Bringing you the latest numbers on monthly fluctuations of
                creator paid partnership rates.
              </Typography>
              <Typography
                style={{
                  textAlign: "left",
                  fontSize: 12,
                }}
              >
                *Please Note: these rates can vary based on the influencer and
                their set rates. Pricing can also change based on factors such
                as, but not limited to, channel, usage rights, exclusivity, link
                in bio, campaign length, and timeline rush
              </Typography>
              <br />
              <br />

              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Creator Level{" "}
                <span style={{ textAlign: "right", marginLeft: 610 }}>
                  {" "}
                  Content Rates
                </span>
              </Typography>

              <Grid
                style={{
                  minHeight: 5,
                  backgroundColor: "black",
                  width: "90%",
                  marginBottom: 30,
                }}
              ></Grid>

              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Micro (0-10K followers){" "}
                <span style={{ textAlign: "right", marginLeft: 580 }}>
                  {" "}
                  $100 - $500
                </span>
              </Typography>

              <Grid
                style={{
                  minHeight: 5,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  width: "90%",
                  marginBottom: 30,
                }}
              ></Grid>

              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Semi-Micro (10K-50k followers){" "}
                <span style={{ textAlign: "right", marginLeft: 500 }}>
                  {" "}
                  $1000 - $2500
                </span>
              </Typography>

              <Grid
                style={{
                  minHeight: 5,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  width: "90%",
                  marginBottom: 30,
                }}
              ></Grid>

              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Semi-Major (50k-100K followers){" "}
                <span style={{ textAlign: "right", marginLeft: 490 }}>
                  {" "}
                  $2000 - $3000
                </span>
              </Typography>

              <Grid
                style={{
                  minHeight: 5,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  width: "90%",
                  marginBottom: 30,
                }}
              ></Grid>

              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Major (100k-500K followers){" "}
                <span style={{ textAlign: "right", marginLeft: 535 }}>
                  {" "}
                  $3000 - $5000
                </span>
              </Typography>

              <Grid
                style={{
                  minHeight: 5,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  width: "90%",
                  marginBottom: 30,
                }}
              ></Grid>

              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Celebrity (500K-1M followers){" "}
                <span style={{ textAlign: "right", marginLeft: 520 }}>
                  {" "}
                  $5000 - $7000
                </span>
              </Typography>

              <Grid
                style={{
                  minHeight: 5,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  width: "90%",
                  marginBottom: 30,
                }}
              ></Grid>

              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Semi-Mega (1M-1.5M){" "}
                <span style={{ textAlign: "right", marginLeft: 580 }}>
                  {" "}
                  $6000 - $9000
                </span>
              </Typography>

              <Grid
                style={{
                  minHeight: 5,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  width: "90%",
                  marginBottom: 30,
                }}
              ></Grid>

              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                Mega (1.5M+ followers){" "}
                <span style={{ textAlign: "right", marginLeft: 600 }}>
                  {" "}
                  $10,000+
                </span>
              </Typography>

              <Grid
                style={{
                  minHeight: 5,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  width: "90%",
                  marginBottom: 30,
                }}
              ></Grid>
              <br />

              <Button
                basic
                style={{
                  borderRadius: 3,
                  width: "40%",
                  marginBottom: 30,
                  backgroundColor: "#4158D0",
                  backgroundImage:
                    "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
                  fontSize: 12,
                }}
                onClick={() => navigate("/campaigns")}
              >
                Vist My Dashboard
              </Button>

              <br />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}
