import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import SocialsOne from "../assets/product_dashboard.png";
import HIWFB1 from "../assets/HIWFB_1.png";
import HIWFB2 from "../assets/HIWFB_2.png";
import HIWFB3 from "../assets/HIWFB_3.png";
import HIWFB4 from "../assets/HIWFB_4.png";
import SocialsTwo from "../assets/launch.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import instagram from "../assets/ig.png";
import tiktok from "../assets/tiktok.png";
import yt from "../assets/yt.png";
import twitter from "../assets/twitter.png";
import Button from "../components/Button";
import { useEffect, useRef } from "react";
import "../assets/socials.css";
import "./Home.css";
import testimonials from "../assets/Testimonials.PNG"

export default function How({ handleOpenWaitlist }) {
  const imageRefOne = useRef();
  const imageRefTwo = useRef();
  const ytRef = useRef();
  const tiktokRef = useRef();
  const igRef = useRef();
  const twitterRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:800px)");
  const startAnimation = () => {
    if (imageRefOne.current) {
      const top = imageRefOne.current.getBoundingClientRect().top;
      const visible = top >= 0 && top <= window.innerHeight - 50;
      if (visible) {
        imageRefOne.current.className = "animate__animated animate__fadeInUp";
        imageRefOne.current.style.setProperty("--animate-duration", "1.5s");
        imageRefOne.current.style.setProperty("visibility", "visible");
      }
    }

    if (imageRefTwo.current) {
      const top = imageRefTwo.current.getBoundingClientRect().top;
      const visible = top >= 0 && top <= window.innerHeight - 50;
      if (visible) {
        setTimeout(() => {
          imageRefTwo.current.className = "animate__animated animate__fadeInUp";
          imageRefTwo.current.style.setProperty("visibility", "visible");
          imageRefTwo.current.style.setProperty("--animate-duration", "1.5s");
        }, 500);
      }
    }

    if (igRef.current) {
      const top = igRef.current.getBoundingClientRect().top;
      const visible = top >= 0 && top <= window.innerHeight - 50;
      if (visible) {
        igRef.current.className = "animate__animated animate__fadeInUp";
        igRef.current.style.setProperty("visibility", "visible");
        igRef.current.style.setProperty("--animate-duration", "1.5s");
      }
    }
    if (tiktokRef.current) {
      const top = tiktokRef.current.getBoundingClientRect().top;
      const visible = top >= 0 && top <= window.innerHeight - 50;
      if (visible) {
        setTimeout(() => {
          tiktokRef.current.className = "animate__animated animate__fadeInUp";
          tiktokRef.current.style.setProperty("visibility", "visible");
          tiktokRef.current.style.setProperty("--animate-duration", "1.5s");
          tiktokRef.current.style.setProperty("--animate-delay", "0.5s");
        }, 500);
      }
    }
    if (ytRef.current) {
      const top = ytRef.current.getBoundingClientRect().top;
      const visible = top >= 0 && top <= window.innerHeight - 50;
      if (visible) {
        setTimeout(() => {
          ytRef.current.className = "animate__animated animate__fadeInUp";
          ytRef.current.style.setProperty("visibility", "visible");
          ytRef.current.style.setProperty("--animate-duration", "1.5s");
          tiktokRef.current.style.setProperty("--animate-delay", "1s");
        }, 1000);
      }
    }
    if (twitterRef.current) {
      const top = twitterRef.current.getBoundingClientRect().top;
      const visible = top >= 0 && top <= window.innerHeight - 50;
      if (visible) {
        setTimeout(() => {
          twitterRef.current.className = "animate__animated animate__fadeInUp";
          twitterRef.current.style.setProperty("visibility", "visible");
          twitterRef.current.style.setProperty("--animate-duration", "1.5s");
          tiktokRef.current.style.setProperty("--animate-delay", "1.5s");
        }, 1500);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      startAnimation();
    });
    startAnimation();
  }, []);
  console.log("ismobile", isMobile);
  return (
    <>
      <div style={{ 
        // backgroundColor: "rgb(244 190 243)", 
        backgroundColor: "#fff0f5",
        minHeight: 600 }}>
        <br />
        <br />
        {isMobile && (
          <>
            <Typography
              style={{
                fontSize: 16,
                color: "black",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              Streamline Campaigns on Gigl
            </Typography>

            <Typography
              style={{
                fontSize: 18,
                color: "black",
                margin: "0 auto",
                textAlign: "center",
                fontWeight: 600,
                width: "80%",
              }}
            >
              Powered By AI, Manage your creator campaigns in one platform
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 12,
                marginRight: 24,
                marginLeft: 24,
                marginTop: 26,
                margin: "0 auto",
                textAlign: "center",
                width: "80%",
              }}
            >
              Find, partner, and collaborate with hundreds of creators for your
              next influencer marketing campaign. Launch paid ad campaigns,
              merchandising partnerships, and creator events. Track performance
              every step of the way.
            </Typography>
            <div
              style={{
                marginTop: 40,
                fontSize: 20,
                marginBottom: 40,
                textAlign: "center",
              }}
            >
              <Button href="/register" style={{ fontSize: isMobile ? 16 : 20 }}>
                {" "}
                Get Started Today
              </Button>
            </div>
          </>
        )}
        <div
          style={{
            width: isMobile ? "95vw" : "80vw",
            margin: "0 auto",
            position: "relative",
          }}
        >
          <Card
            style={{
              zIndex: -1,
              float: isMobile ? "" : "left",
              width: isMobile ? "" : "35vw",
              minWidth: isMobile ? "10" : "35vw",
              borderRadius: 20,
              backgroundImage: "linear-gradient(white 30%, rgb(244 190 243))",
              border: "2px solid white",
              marginBottom: 20,
            }}
          >
            <CardContent sx={{ position: "relative", height: 500 }}>
              <div className="line">
                <div
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    width: "100%",
                    left: 0,
                  }}
                >
                  <Card
                    style={{
                      width: isMobile ? "80vw" : "25vw",
                      minWidth: 200,
                      position: "absolute",
                      left: "50%",
                      transform: "translate(-50%)",
                      borderRadius: 20,
                      textAlign: "center",
                      marginBottom: 15,
                      backgroundColor: "#ff6efc",
                    }}
                  >
                    <Typography
                      style={{
                        color: "black",
                        fontSize: 15,
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontWeight: 600,
                      }}
                      variant="body1"
                    >
                      Launch Campaigns
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      width: isMobile ? "80vw" : "25vw",
                      minWidth: 250,
                      margin: "0 auto",
                      borderRadius: 20,
                      position: "absolute",
                      left: "50%",
                      transform: "translate(-50%)",
                      top: 55,
                      textAlign: "center",
                      marginBottom: 15,
                      backgroundColor: "rgb(248 156 246)",
                    }}
                  >
                    <Typography
                      style={{
                        color: "black",
                        fontSize: 15,
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontWeight: 600,
                      }}
                      variant="body1"
                    >
                      {" "}
                      Match With Top Creators{" "}
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      width: isMobile ? "80vw" : "25vw",
                      minWidth: 200,
                      margin: "0 auto",
                      borderRadius: 20,
                      position: "absolute",
                      left: "50%",
                      transform: "translate(-50%)",
                      top: 110,
                      textAlign: "center",
                      marginBottom: 15,
                      backgroundColor: "rgb(255 198 254)",
                    }}
                  >
                    <Typography
                      style={{
                        color: "black",
                        fontSize: 15,
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontWeight: 600,
                      }}
                      variant="body1"
                    >
                      Drive Business Results{" "}
                    </Typography>
                  </Card>
                  <Card
                    style={{
                      width: isMobile ? "80vw" : "25vw",
                      minWidth: 200,
                      margin: "0 auto",
                      borderRadius: 20,
                      position: "absolute",
                      textAlign: "center",
                      left: "50%",
                      transform: "translate(-50%)",
                      top: 165,
                      marginBottom: 15,
                      backgroundColor: "rgb(255 226 254)",
                    }}
                  >
                    <Typography
                      style={{
                        color: "black",
                        fontSize: 15,
                        paddingTop: 10,
                        paddingBottom: 10,
                        fontWeight: 600,
                      }}
                      variant="body1"
                    >
                      Measure Performance
                    </Typography>
                  </Card>
                  <img
                    ref={imageRefOne}
                    src={SocialsOne}
                    style={{
                      width: "100%",
                      visibility: "hidden",
                      filter: "hue-rotate(34deg)",
                      top: 200,
                      position: "absolute",
                      left: 0,
                    }}
                  ></img>
                </div>
              </div>
            </CardContent>
          </Card>
          {isMobile && <br />}
          {!isMobile && (
            <div
              style={{
                marginTop: -10,
                textAlign: "right",
                float: "right",
                width: "40vw",
              }}
            >
              <Typography
                style={{
                  fontSize: 25,
                  color: "black",
                  marginRight: 0,
                  marginLeft: "auto",
                  marginTop: 40,
                }}
              >
                Streamline Campaigns on Gigl
              </Typography>

              <Typography
                style={{
                  fontSize: 30,
                  color: "black",
                  marginRight: 0,
                  marginLeft: "auto",
                  marginTop: 10,
                  fontWeight: 600,
                }}
              >
                Powered By AI, Manage Your Creator Campaigns In One Platform
              </Typography>
              <Typography
                variant="body1"
                style={{ marginRight: 0, marginLeft: "auto", marginTop: 20, width: "90%" }}
              >
                Find, partner, and collaborate with hundreds of creators for
                your next influencer marketing campaign. From paid ad
                campaigns, affiliate partnerships, to creator events, Gigl can
                support your team, and help you track performance every step of the way in
                your custom dashboard.
              </Typography>
              <Button style={{ marginTop: 50, fontSize: 20 }} href="/register">
                Get Started Today
              </Button>
            </div>
          )}
        </div>

        {/* <div style={{display:"inline-flex", gap: 40, float: 'left', position: 'absolute'}}>
      <div ref={igRef} style={{width: 60, height: 60, borderRadius: 10, backgroundColor: "transparent", visibility: 'hidden'}}>
      <img  src={instagram} style={{objectFit: "contain"}} height="100%"></img>
      </div>
      <div ref={tiktokRef} style={{width: 60, height: 60, borderRadius: 10, backgroundColor: "transparent", visibility: 'hidden'}}>
      <img  src={tiktok} style={{objectFit: "contain"}} height="90%"></img>
      </div>
      <div  ref={ytRef} style={{width: 60, height: 60, borderRadius: 10, backgroundColor: "transparent", visibility: 'hidden'}}>
      <img src={yt} style={{objectFit: "contain", marginTop: 2}} height="90%"></img>
      </div>
      <div ref={twitterRef} style={{width: 60, height: 60, borderRadius: 10, backgroundColor: "transparent", visibility: 'hidden'}}>
      <img  src={twitter} style={{objectFit: "contain"}} height="100%"></img>
      </div>
      </div>
    <div style={{maxHeight: '50vh', overflow: 'none'}}>
   
    
    <img ref={imageRefTwo} src={SocialsTwo} style={{height: '40vh', marginBottom: 30, visibility: 'hidden',  borderRadius: 20}}></img>
    </div> */}
      </div>
      <Grid
        container
        sx={{
          // margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: "black", minHeight: 10, }}
      ></Grid>

      {isMobile ? (
        <Grid>
          <Grid
            item
            xs={11}
            md={5}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <img
              alt=""
              fetchpriority="high"
              src={HIWFB1}
              style={{
                width: "90%",
                maxWidth: isMobile ? "50vw" : "20vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={HIWFB2}
              style={{
                width: "90%",
                maxWidth: isMobile ? "50vw" : "20vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
          <Grid>
            <img
              alt=""
              fetchpriority="high"
              src={HIWFB3}
              style={{
                width: "90%",
                maxWidth: isMobile ? "50vw" : "20vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={HIWFB4}
              style={{
                width: "90%",
                maxWidth: isMobile ? "50vw" : "20vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={11}
            md={5}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <img
              alt=""
              fetchpriority="high"
              src={HIWFB1}
              style={{
                width: "90%",
                maxWidth: isMobile ? "21vw" : "20vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={HIWFB2}
              style={{
                width: "90%",
                maxWidth: isMobile ? "21vw" : "20vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={HIWFB3}
              style={{
                width: "90%",
                maxWidth: isMobile ? "21vw" : "20vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>

            <img
              alt=""
              fetchpriority="high"
              src={HIWFB4}
              style={{
                width: "90%",
                maxWidth: isMobile ? "21vw" : "20vw",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
        </>
      )}

 {isMobile ? (
        <Grid  

          sx={{ textAlign: "center" }}
                    alignItems="center"
          justifyContent="center"
          
          > 

            <Typography variant="h6" style={{ fontWeight: 600, maWidth: "95%" }}>
                Hear What Top Brands & Agencies 
                <br/>
                Have to Say About Gigl.
            </Typography>

            <img
              alt=""
              fetchpriority="high"
              src={testimonials}
              style={{
                height: "90%",
                width: "90%",
              }}
            ></img>
          </Grid>
          ) : (
            <>
                    <Grid  

                  sx={{ textAlign: "center" }}
                            alignItems="center"
                  justifyContent="center"

                  > 

                    <Typography variant="h3" style={{ fontWeight: 600 }}>
                        Hear What Top Brands & Agencies 
                        <br/>
                        Have to Say About Gigl.
                    </Typography>

                    <img
                      alt=""
                      fetchpriority="high"
                      src={testimonials}
                      style={{
                        height: "60%",
                        width: "60%",
                      }}
                    ></img>
                  </Grid>
                  </>
      )}

    </>
  );
}
