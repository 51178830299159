import {
  Typography,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Paper,
  CardHeader,
  Avatar,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { db } from "../util/firebase";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import { Line, Bar } from "react-chartjs-2";
import instagram from "../assets/ig.png";
import tiktok from "../assets/tiktok.png";
import yt from "../assets/yt.png";
import twitter from "../assets/twitter.png";
import "chart.js/auto"; // ADD THIS
import { fetchGet, fetchPost, formatStat } from "../util/helpers";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import UserContext from "../contexts/UserContext";
import LoadingContext from "../contexts/LoadingContext";

const CreatorCard = ({ creator, handleClick }) => {
  return (
    <div
      key={creator.id}
      style={{ position: "relative", maxWidth: 360, minWidth: 330 }}
    >
      <Card
        sx={{
          maxWidth: 345,
          margin: 1,
          borderRadius: 5,
          height: 330,
          ...(creator.response.status === "matched" && {
            boxShadow: `1px 1px 14px 1px rgb(213, 126, 235)`,
          }),
        }}
      >
        <CardContent>
          <Avatar
            src={creator.images && creator.images[0]}
            sx={{ width: 100, height: 100 }}
            style={{ float: "left", marginRight: 10 }}
          />

          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ marginTop: 1 }}
          >
            {creator.name}
          </Typography>
          <Typography gutterBottom variant="body1" component="div">
            {creator.industries &&
              (creator.industries.length > 3
                ? creator.industries.slice(0, 3).join(", ") + "..."
                : creator.industries.join(", "))}
          </Typography>
          <br />
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ textAlign: "center" }}
          >
            <Grid item xs={4}>
              <Typography gutterBottom variant="h5" component="div">
                {creator.stats && formatStat(creator.stats.totalFollowers)}
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                Followers
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="h5" component="div">
                {creator.stats && formatStat(creator.stats.totalPosts)}
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                Posts
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="h5" component="div">
                {creator.stats && formatStat(creator.stats.totalReach)}
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                Reach
              </Typography>
            </Grid>
          </Grid>
          <div style={{ margin: "0 auto", textAlign: "center" }}>
            {creator.images && creator.images.slice(1).map((img, index) => (
              <img
                src={img}
                height={"100px"}
                width={"100px"}
                style={{
                  marginRight:
                    index === creator.images.slice(1).length - 1 ? 0 : "20px",
                  borderRadius: 5,
                  objectFit: "cover",
                }}
              />
            ))}
          </div>
          <Button
            style={{
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
              position: "absolute",
              zIndex: 100,
              bottom: 0,
              textAlign: "center",
              marginBottom: -10,
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
            onClick={() => {
              handleClick(creator);
            }}
          >
            {creator.response.status === "matched"
              ? "View Partner"
              : "View More"}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default memo(CreatorCard);
