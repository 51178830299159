import { auth, storage, db } from "./firebase";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  deleteUser,
} from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
export async function handleUpload(file, path, fileId) {
  if (!file) {
    alert("Please choose a file first!");
  }

  const storageRef = ref(storage, `/${path}/${fileId}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  await uploadTask;
  return await getDownloadURL(uploadTask.snapshot.ref);
}

const fetchHelper = async (path, type, body = null, setIsLoading) => {
  const options = {
    method: type,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let authToken = null;
  try {
    authToken = await getIdToken(auth.currentUser);
  } catch (error) {
    authToken = null;
  }
  if (authToken) {
    options.headers.Authorization = `Bearer ${authToken}`;
  }
  if (body) {
    options.body = JSON.stringify(body);
  }
  if (setIsLoading) {
    setIsLoading(true);
  }
  const URL = `${
    process.env.REACT_APP_BACKEND_URL.includes(".com") ? "https:" : "http:"
  }//${process.env.REACT_APP_BACKEND_URL}`;
  return fetch(`${URL}${path}`, options).then((response) => {
    if (setIsLoading) {
      setIsLoading(false);
    }
    if (!response.ok) {
      return Promise.reject(response);
    }
    let result;
    try {
      result = response.json();
    } catch (err) {
      console.log("err parse as text");
      result = response.text();
    }
    return result;
  });
};

export const fetchGet = async (path, setIsLoading) => {
  return fetchHelper(path, "GET", null, setIsLoading);
};

export const fetchPost = async (path, body, setIsLoading) => {
  return fetchHelper(path, "POST", body, setIsLoading);
};

export const fetchPut = async (path, body, setIsLoading) => {
  return fetchHelper(path, "PUT", body, setIsLoading);
};

export const fetchDelete = async (path, body, setIsLoading) => {
  return fetchHelper(path, "DELETE", body, setIsLoading);
};

export const fetchPatch = async (path, body, setIsLoading) => {
  return fetchHelper(path, "PATCH", body, setIsLoading);
};

export function idGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}
export async function signUpWithEmail(email, pw) {
  const res = await createUserWithEmailAndPassword(auth, email, pw);
  const user = res.user;
  try {
    const result = await fetchPost("/auth/register", {
      id: user.uid,
      name: user.displayName || "",
      authProvider: "email",
      email: user.email,
      type: "business",
    });
    return { ...user, ...result.result };
  } catch (err) {
    console.log(err);
    alert(
      "An error occurred signing up, please contact us at support@giglgroup.com",
    );
  }

  return user;
}

export async function creatorSignUpWithEmail(email, pw) {
  const res = await createUserWithEmailAndPassword(auth, email, pw);
  const user = res.user;
  try {
    const result = await fetchPost("/auth/register", {
      id: user.uid,
      name: user.displayName || "",
      authProvider: "email",
      email: user.email,
      type: "creator",
    });
    return { ...user, ...result.result };
  } catch (err) {
    console.log(err);
    alert(
      "An error occurred signing up, please contact us at support@giglgroup.com",
    );
  }

  return user;
}

export function verifyEmail(user) {
  return sendEmailVerification(user);
}

export async function logout() {
  await signOut(auth);
  // window.location.href = "/login"
}

export async function signInWithEmail(email, pw) {
  return await signInWithEmailAndPassword(auth, email, pw);
  // const user = res.user;
  // try {
  //   await fetchPost("/auth/register", {
  //     id: user.uid,
  //     name: user.displayName,
  //     authProvider: "email",
  //     email: user.email,
  //     type: "business",
  //   });
  // } catch (err) {
  //   console.log(err)
  //   alert("An error occurred signing up, please contact us at support@giglgroup.com")
  // }
  // return user;
}

export async function signInWithGoogle() {
  const res = await signInWithPopup(auth, googleProvider);
  const user = res.user;
  try {
    const result = await fetchPost("/auth/register", {
      id: user.uid,
      name: user.displayName,
      authProvider: "google",
      email: user.email,
      type: "business",
    });
    return { ...user, ...result.result };
  } catch (err) {
    console.log(err);
    alert(
      "An error occurred signing up, please contact us at support@giglgroup.com",
    );
  }

  return user;
}

export async function signInWithApple() {
  const res = await signInWithPopup(auth, appleProvider);
  const user = res.user;
  try {
    await fetchPost("/auth/register", {
      name: user.displayName,
      authProvider: "apple",
      email: user.email,
      type: "business",
    });
  } catch (err) {
    console.log(err);
    alert(
      "An error occurred signing up, please contact us at support@giglgroup.com",
    );
  }

  return user;
}

export async function resetPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

export function validateEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
}

export async function isCurrentUserEmailVerified() {
  await auth.currentUser.reload();
  const user = auth.currentUser;
  return user.emailVerified;
}

export function getCurrentUser() {
  return auth.currentUser;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
// Generate a random integer between min and max (inclusive)
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const generateMockAnalytics = (startDate, endDate, interval = 1) => {
  const data = [];

  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const date = currentDate.toISOString().split("T")[0];
    const youtubeViewCount = getRandomInt(1000, 10000);
    const instagramViewCount = getRandomInt(500, 5000);
    const tiktokViewCount = getRandomInt(100, 2000);
    const facebookViewCount = getRandomInt(100, 5000);

    data.push({
      date,
      youtubeViewCount,
      instagramViewCount,
      tiktokViewCount,
      facebookViewCount,
    });

    currentDate.setDate(currentDate.getDate() + interval);
  }

  return data;
};

export const transformFirebaseTime = (time) => {
  if (time === undefined) {
    return undefined;
  }
  // already a date string
  if (typeof time === "string" || time instanceof String) {
    return new Date(time);
  }

  const fireBaseTime = new Date(
    (time.seconds||time._seconds) * 1000 + (time.nanoseconds||time._nanoseconds) / 1000000,
  );
  return fireBaseTime;
};

export function formatStat(stat) {
  if (!stat) {
    return 0;
  }
  return Math.abs(Number(stat)) >= 1.0e9
    ? Math.round(Math.abs(Number(stat)) / 1.0e9) + "B"
    : // Six Zeroes for Millions
      Math.abs(Number(stat)) >= 1.0e6
      ? Math.round(Math.abs(Number(stat)) / 1.0e6) + "M"
      : // Three Zeroes for Thousands
        Math.abs(Number(stat)) >= 1.0e3
        ? Math.round(Math.abs(Number(stat)) / 1.0e3) + "K"
        : Math.round(Math.abs(Number(stat)));
}

export function parseFollowers(followersString) {
  const suffixes = {
    "k": 1000,
    "m": 1000000,
    "b": 1000000000
  };
  const matches = followersString && followersString.match(/^(\d*\.?\d*)([KMBkmb])$/);
  if (!matches) return 0;

  const value = parseFloat(matches[1]);
  const suffix = matches[2].toString().toLowerCase();
  if (!(suffix in suffixes)) return 0;

  return value * suffixes[suffix];
}

export const celebrityRanges = {
  "Micro": { min: 0, max: parseFollowers("50K") },
  "Major": { min: parseFollowers("50K") + 1, max: parseFollowers("150K") },
  "Celebrity": { min: parseFollowers("150K") + 1, max: Infinity }
};

export const getCelebrityRange = (followersCount) => {
  for (const [key, range] of Object.entries(celebrityRanges)) {
    if (followersCount >= range.min && followersCount <= range.max) {
      return key;
    }
  }
  return "Unknown";
};

export const getRateRange = (followers) => {
  if (followers < 10000) return "$100-$500";
  if (followers > 10000 && followers < 50000) return "$500-$2000";
  if (followers >= 50000 && followers < 100000) return "$2000-$2800";
  if (followers >= 100000 && followers < 500000) return "$3000-$5000";
  if (followers >= 500000 && followers < 1000000) return "$5000-$7000";
  if (followers >= 1000000 && followers < 1500000) return "$6000-$8000";
  if (followers >= 1500000) return "8000-$10,000+";
}

export function getBase64(file) {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export const GenderOptions = [
  { label: "Female", value: "female" },
  { label: "Male", value: "male" },
  { label: "Non-Binary", value: "non-binary" },
];

export const GenderValueToLabelMap = GenderOptions.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {}); 

export const convertDate = (date) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const month = months[date.getMonth()]; // Get month name
  const day = date.getDate();
  const year = date.getFullYear();
  const time = date.toLocaleTimeString([],{
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });


  // Format day with suffix
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  return `${month} ${day}${daySuffix(day)}, ${year} ${time}`;
}

export const getFileType = (fileUrl) => {
  if(typeof fileUrl != "string") return "unknown"; 
  
  const extension = fileUrl?.split('?').slice(-2, -1)[0].split('.').pop()?.toLowerCase() || "";

  if (['jpg', 'jpeg', 'png','webp'].includes(extension)) {
    return 'image';
  } else if (['docx', 'pptx','doc','pdf'].includes(extension)) {
    return 'document';
  } else if(['mp4','avi','mov'].includes(extension)){
    return 'video';
  }
  else{
    return 'unknown'; 
  }
};

export const fetchFile = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], "firebase-file", { type: blob.type });
  return file;
};
